/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { Input, HostBinding, Directive, ElementRef, Renderer2, NgZone, forwardRef, Component, NgModule, EventEmitter, isDevMode, ContentChild, ChangeDetectorRef, ViewChild } from '@angular/core';
import { isDocumentAvailable, guid, KendoInput } from '@progress/kendo-angular-common';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { CommonModule } from '@angular/common';
import { NgControl } from '@angular/forms';
import { validatePackage } from '@progress/kendo-licensing';
import { Observable, Subscription } from 'rxjs';

/**
 * @hidden
 */
const isUploadComponent = (component) => component.wrapper && (component.wrapper.tagName === 'KENDO-UPLOAD' || component.wrapper.tagName === 'KENDO-FILESELECT');
/**
 * @hidden
 */
const isInputElement = (component) => component instanceof HTMLElement;
/**
 * @hidden
 */
const inputElementHasAttr = (element, attribute) => element.hasAttribute(attribute);
/**
 * @hidden
 */
const getWrappedNativeInput = (element) => element.querySelector('kendo-label > input, kendo-label > textarea, kendo-label > select');
/**
 * @hidden
 */
const getRootElement = (element) => {
    if (!element) {
        return null;
    }
    let rootElement = element;
    while (rootElement.parentElement) {
        rootElement = rootElement.parentElement;
    }
    return rootElement;
};

/**
 * Represents the [Kendo UI Label directive for Angular]({% slug overview_label %}).
 * The Label associates a focusable Angular component or an HTML element
 * with a `label` tag by using the `[for]` property binding.
 *
 * To associate a component by using the `label` element, either:
 * * Set the `[for]` property binding to a
 * [template reference variable]({{ site.data.urls.angular['templatesyntax'] }}#template-reference-variables--var-), or
 * * Set the `[for]` property binding to an `id` HTML string value.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <div class="row example-wrapper" style="min-height: 450px;">
 *    <div class="col-xs-12 col-md-6 example-col">
 *      <label [for]="datepicker">DatePicker: </label>
 *      <kendo-datepicker #datepicker></kendo-datepicker>
 *    </div>
 *
 *    <div class="col-xs-12 col-md-6 example-col">
 *      <label [for]="'input'">Input: </label>
 *      <input id="input" />
 *    </div>
 *  </div>
 * `
 * })
 * class AppComponent { }
 * ```
 */
let LabelDirective = class LabelDirective {
    constructor(label, renderer, zone) {
        this.label = label;
        this.renderer = renderer;
        this.zone = zone;
        this.labelClass = true;
        this.handleClick = () => {
            const component = this.getFocusableComponent();
            if (!component) {
                return;
            }
            if (isUploadComponent(component)) {
                component.fileSelect.nativeElement.click();
            }
            if (component.focus) {
                component.focus();
            }
        };
    }
    get labelFor() {
        if (typeof this.for === 'string') {
            return this.for;
        }
        if (!isDocumentAvailable()) {
            return null;
        }
        const component = this.getFocusableComponent() || {};
        if (isInputElement(component) && !inputElementHasAttr(component, 'id')) {
            this.renderer.setAttribute(component, 'id', `k-${guid()}`);
        }
        return component.focusableId || component.id || null;
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        this.setAriaLabelledby();
        this.zone.runOutsideAngular(() => this.clickListener = this.renderer.listen(this.label.nativeElement, 'click', this.handleClick));
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        if (this.clickListener) {
            this.clickListener();
        }
    }
    /**
     * @hidden
     */
    setAriaLabelledby() {
        if (!isDocumentAvailable()) {
            return;
        }
        const component = this.getFocusableComponent();
        if (component && component.focusableId) {
            const rootElement = getRootElement(this.label.nativeElement);
            const labelTarget = rootElement.querySelector(`#${component.focusableId}`);
            if (!labelTarget) {
                return;
            }
            const labelElement = this.label.nativeElement;
            const id = labelElement.id || `k-${guid()}`;
            if (!labelElement.getAttribute('id')) {
                this.renderer.setAttribute(labelElement, 'id', id);
            }
            this.renderer.setAttribute(labelTarget, 'aria-labelledby', id);
        }
    }
    getFocusableComponent() {
        const target = this.for;
        return target && target.focus !== undefined ? target : null;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], LabelDirective.prototype, "for", void 0);
__decorate([
    HostBinding('attr.for'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], LabelDirective.prototype, "labelFor", null);
__decorate([
    HostBinding('class.k-label'),
    __metadata("design:type", Boolean)
], LabelDirective.prototype, "labelClass", void 0);
LabelDirective = __decorate([
    Directive({
        selector: 'label[for]' //tslint:disable-line:directive-selector
    }),
    __metadata("design:paramtypes", [ElementRef, Renderer2, NgZone])
], LabelDirective);

/**
 * @hidden
 */
class Messages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], Messages.prototype, "optional", void 0);

var LocalizedMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedMessagesDirective = LocalizedMessagesDirective_1 = class LocalizedMessagesDirective extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedMessagesDirective = LocalizedMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => LocalizedMessagesDirective_1)
            }
        ],
        selector: `
      [kendoLabelLocalizedMessages],
      [kendoFloatingLabelLocalizedMessages]
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedMessagesDirective);

var CustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug label_globalization %}#toc-localization)).
 */
let CustomMessagesComponent = CustomMessagesComponent_1 = class CustomMessagesComponent extends Messages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
CustomMessagesComponent = CustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: Messages,
                useExisting: forwardRef(() => CustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-label-messages, kendo-floatinglabel-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CustomMessagesComponent);

const SHARED_DIRECTIVES = [
    LocalizedMessagesDirective,
    CustomMessagesComponent
];
/**
 * @hidden
 */
let SharedDirectivesModule = class SharedDirectivesModule {
};
SharedDirectivesModule = __decorate([
    NgModule({
        declarations: [SHARED_DIRECTIVES],
        exports: [SHARED_DIRECTIVES]
    })
], SharedDirectivesModule);

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-label',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620214538,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
class FloatingLabelInputAdapter {
    constructor(component, formControl) {
        this.component = component;
        const isObservableOrEventEmitter = (event) => event instanceof Observable || event instanceof EventEmitter;
        if (isObservableOrEventEmitter(component.onFocus)) {
            this.onFocus = component.onFocus;
        }
        if (isObservableOrEventEmitter(component.autoFillStart)) {
            this.autoFillStart = component.autoFillStart;
        }
        if (isObservableOrEventEmitter(component.autoFillEnd)) {
            this.autoFillEnd = component.autoFillEnd;
        }
        if (isObservableOrEventEmitter(component.onBlur)) {
            this.onBlur = component.onBlur;
        }
        if (formControl) {
            this.onValueChange = formControl.valueChanges;
        }
        else if (component.onValueChange) {
            this.onValueChange = component.onValueChange;
        }
    }
    get focusableId() {
        const component = this.component;
        if ('focusableId' in component) {
            return component.focusableId;
        }
        else if ('id' in component) {
            return component.id;
        }
        return "";
    }
    set focusableId(value) {
        const component = this.component;
        if ('focusableId' in component) {
            component.focusableId = value;
        }
        else if ('id' in component) {
            component.id = value;
        }
    }
}

const isFunction = (x) => Object.prototype.toString.call(x) === '[object Function]';
/**
 * Represents the [Kendo UI FloatingLabel component for Angular]({% slug overview_floatinglabel %}).
 * Provides floating labels to `input` elements.
 *
 * The FloatingLabel supports both Template and Reactive Forms and
 * [can contain Kendo UI for Angular Input components such as `kendo-combobox` and `kendo-numerictextbox`,
 * or HTML Input elements with the `kendoTextBox` directive applied]({% slug overview_floatinglabel %}#toc-implementing-floating-labels).
 *
 * @example
 * ```ts
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *     <kendo-floatinglabel text="First name">
 *       <input [(ngModel)]="name" kendoTextBox />
 *     </kendo-floatinglabel>
 *   `
 * })
 * class AppComponent {
 *     public name = 'John';
 * }
 *
 * ```
 */
let FloatingLabelComponent = class FloatingLabelComponent {
    constructor(elementRef, renderer, changeDetectorRef, localization) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.changeDetectorRef = changeDetectorRef;
        this.localization = localization;
        this.hostClasses = true;
        /**
         * @hidden
         */
        this.focused = false;
        /**
         * @hidden
         */
        this.empty = true;
        /**
         * @hidden
         */
        this.invalid = false;
        /**
         * @hidden
         */
        this.labelId = `k-${guid()}`;
        this.autoFillStarted = false;
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.renderer.removeAttribute(this.elementRef.nativeElement, "id");
    }
    get focusedClass() {
        return this.focused;
    }
    get invalidClass() {
        return this.invalid;
    }
    /**
     * @hidden
     */
    ngAfterContentInit() {
        this.validateSetup();
        const control = new FloatingLabelInputAdapter(this.kendoInput || this.formControl.valueAccessor, this.formControl);
        this.addHandlers(control);
        this.setLabelFor(control);
    }
    ngAfterViewInit() {
        if (this.kendoInput) {
            this.setAriaLabelledby(this.kendoInput);
        }
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    textFor(key) {
        return this.localization.get(key);
    }
    subscribe(control, eventName, handler) {
        if (control[eventName] instanceof EventEmitter) {
            const subscription = control[eventName].subscribe(handler);
            if (!this.subscription) {
                this.subscription = subscription;
            }
            else {
                this.subscription.add(subscription);
            }
        }
    }
    updateState() {
        const empty = value => {
            // zero is not an empty value (e.g., NumericTextBox)
            if (value === 0 || value === false) {
                return false;
            }
            // empty arrays are an empty value (e.g., MultiSelect)
            if (Array.isArray(value) && !value.length) {
                return true;
            }
            return !value;
        };
        const formControl = this.formControl;
        if (formControl) {
            const valueAccessor = formControl.valueAccessor;
            if (isFunction(valueAccessor.isEmpty)) {
                this.empty = valueAccessor.isEmpty();
            }
            else {
                this.empty = empty(formControl.value);
            }
            this.invalid = formControl.invalid && (formControl.touched || formControl.dirty);
        }
        else {
            this.empty = isFunction(this.kendoInput.isEmpty) ?
                this.kendoInput.isEmpty() : empty(this.kendoInput.value);
        }
        if (this.empty) {
            this.renderer.addClass(this.elementRef.nativeElement, 'k-state-empty');
        }
        else {
            this.renderer.removeClass(this.elementRef.nativeElement, 'k-state-empty');
        }
        this.changeDetectorRef.markForCheck();
    }
    setAriaLabelledby(component) {
        const componentId = component.focusableId || component.id;
        if (componentId) {
            const focusableElement = this.elementRef.nativeElement.querySelector(`#${componentId}`);
            this.renderer.setAttribute(focusableElement, 'aria-labelledby', this.labelId);
        }
    }
    setLabelFor(control) {
        const controlId = control.focusableId || control.id;
        if (this.id && controlId) {
            // input wins
            this.id = controlId;
        }
        else if (this.id) {
            control.focusableId = this.id;
        }
        else if (controlId) {
            this.id = controlId;
        }
        else {
            const id = `k-${guid()}`;
            control.focusableId = id;
            this.id = id;
        }
    }
    handleAutofill(control) {
        this.subscribe(control, 'autoFillStart', () => {
            this.autoFillStarted = true;
            this.renderer.removeClass(this.elementRef.nativeElement, 'k-state-empty');
        });
        this.subscribe(control, 'autoFillEnd', () => {
            if (this.autoFillStarted) {
                this.autoFillStarted = false;
                if (this.empty) {
                    this.renderer.addClass(this.elementRef.nativeElement, 'k-state-empty');
                }
            }
        });
    }
    addHandlers(control) {
        const setFocus = (isFocused) => () => {
            this.focused = isFocused;
            this.updateState();
        };
        this.subscribe(control, 'onFocus', setFocus(true));
        this.subscribe(control, 'onBlur', setFocus(false));
        this.handleAutofill(control);
        const updateState = () => this.updateState();
        updateState();
        this.subscribe(control, 'onValueChange', updateState);
    }
    validateSetup() {
        if (!this.formControl && !this.kendoInput) {
            if (isDevMode()) {
                throw new Error("The FloatingLabelComponent requires a Kendo Input component" +
                    " or a forms-bound component to function properly.");
            }
            return;
        }
    }
};
__decorate([
    HostBinding('class.k-floating-label-container'),
    __metadata("design:type", Boolean)
], FloatingLabelComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-state-focused'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], FloatingLabelComponent.prototype, "focusedClass", null);
__decorate([
    HostBinding('class.k-state-invalid'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], FloatingLabelComponent.prototype, "invalidClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], FloatingLabelComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingLabelComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingLabelComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FloatingLabelComponent.prototype, "optional", void 0);
__decorate([
    ContentChild(KendoInput, { static: false }),
    __metadata("design:type", Object)
], FloatingLabelComponent.prototype, "kendoInput", void 0);
__decorate([
    ContentChild(NgControl, { static: false }),
    __metadata("design:type", NgControl)
], FloatingLabelComponent.prototype, "formControl", void 0);
FloatingLabelComponent = __decorate([
    Component({
        selector: 'kendo-floatinglabel',
        exportAs: 'kendoFloatingLabel',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.floatinglabel'
            }
        ],
        template: `
        <ng-container kendoFloatingLabelLocalizedMessages
            i18n-optional="kendo.floatinglabel.optional|The text for the optional segment of a FloatingLabel component"
            optional="Optional"
         >
        </ng-container>
        <ng-content></ng-content>
        <label *ngIf="text" [for]="id" [attr.id]="labelId" class="k-label">
            {{ text }}<span *ngIf="optional" class="k-label-optional">({{textFor('optional')}})</span>
        </label>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        ChangeDetectorRef,
        LocalizationService])
], FloatingLabelComponent);

const COMPONENT_DIRECTIVES = [FloatingLabelComponent];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the TextBox directive.
 *
 * @example
 *
 * ```ts-no-run
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FloatingLabelModule], // import FloatingLabel module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let FloatingLabelModule = class FloatingLabelModule {
};
FloatingLabelModule = __decorate([
    NgModule({
        declarations: [...COMPONENT_DIRECTIVES],
        exports: [...COMPONENT_DIRECTIVES, SharedDirectivesModule],
        imports: [CommonModule, SharedDirectivesModule]
    })
], FloatingLabelModule);

/**
 * Represents the [Kendo UI Label component for Angular]({% slug label_basic %}).
 *
 * Associates a label with input elements or components.
 *
 * @example
 * ```ts
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *     <kendo-label [for]="input" text="First name">
 *       <input [(ngModel)]="name" kendoTextBox #input />
 *     </kendo-label>
 *   `
 * })
 * class AppComponent {
 *     public name = 'John';
 * }
 *
 * ```
 */
let LabelComponent = class LabelComponent {
    constructor(elementRef, renderer, localization) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.localization = localization;
        this.subscriptions = new Subscription();
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
    }
    /**
     * @hidden
     */
    ngAfterContentInit() {
        if (this.for) {
            this.control = this.for;
            return;
        }
        const wrappedNativeInput = getWrappedNativeInput(this.elementRef.nativeElement);
        if (wrappedNativeInput) {
            if (!wrappedNativeInput.hasAttribute('id')) {
                this.renderer.setAttribute(wrappedNativeInput, 'id', `k-${guid()}`);
            }
            this.control = wrappedNativeInput;
            return;
        }
        this.control = this.kendoInput;
    }
    /**
     * @hidden
     */
    ngOnInit() {
        this.subscriptions.add(this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        }));
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        this.labelDirective.setAriaLabelledby();
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    textFor(key) {
        return this.localization.get(key);
    }
};
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], LabelComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LabelComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LabelComponent.prototype, "for", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], LabelComponent.prototype, "optional", void 0);
__decorate([
    ViewChild(LabelDirective, { static: true }),
    __metadata("design:type", LabelDirective)
], LabelComponent.prototype, "labelDirective", void 0);
__decorate([
    ContentChild(KendoInput, { static: true }),
    __metadata("design:type", Object)
], LabelComponent.prototype, "kendoInput", void 0);
LabelComponent = __decorate([
    Component({
        selector: 'kendo-label',
        exportAs: 'kendoLabel',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.label'
            }
        ],
        template: `
        <ng-container kendoLabelLocalizedMessages
            i18n-optional="kendo.label.optional|The text for the optional segment of a Label component"
            optional="Optional"
         >
        </ng-container>
        <label
            [for]="control"
            [class.k-label-empty]="!text">
            {{ text }}<span *ngIf="optional" class="k-label-optional">({{textFor('optional')}})</span>
        </label>
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        LocalizationService])
], LabelComponent);

const COMPONENT_DIRECTIVES$1 = [
    LabelDirective,
    LabelComponent
];
/**
 * The exported package module.
 *
 * The package exports:
 * - `LabelDirective`&mdash;The Label directive class.
 * - `LabelComponent`&mdash;The Label component class
 * - `FLoatingLabel`&mdash;The FloatingLabel component class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Label module
 * import { LabelModule } from '@progress/kendo-angular-label';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, LabelModule], // import Label module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let LabelModule = class LabelModule {
};
LabelModule = __decorate([
    NgModule({
        imports: [CommonModule, SharedDirectivesModule],
        declarations: [...COMPONENT_DIRECTIVES$1],
        exports: [...COMPONENT_DIRECTIVES$1, FloatingLabelModule, SharedDirectivesModule]
    })
], LabelModule);

/**
 * Generated bundle index. Do not edit.
 */

export { LocalizedMessagesDirective, Messages, SharedDirectivesModule, LabelDirective, LabelModule, FloatingLabelModule, FloatingLabelComponent, LabelComponent, CustomMessagesComponent };
