/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { Injectable, EventEmitter, Input, Output, HostBinding, HostListener, Directive, Optional, ElementRef, Renderer2, NgZone, isDevMode, ContentChildren, QueryList, Component, NgModule, TemplateRef, ViewContainerRef, InjectionToken, Inject, ContentChild, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subject, fromEvent, merge, Subscription } from 'rxjs';
import { Keys, isChanged, isDocumentAvailable, hasObservers, guid, EventsModule } from '@progress/kendo-angular-common';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { filter, take } from 'rxjs/operators';
import { validatePackage } from '@progress/kendo-licensing';
import { CommonModule } from '@angular/common';
import { PopupService, PopupModule } from '@progress/kendo-angular-popup';
import { sequence, query, style, stagger, animate, AnimationBuilder } from '@angular/animations';

/**
 * @hidden
 */
let KendoButtonService = class KendoButtonService {
    /**
     * @hidden
     */
    constructor() {
        this.buttonLookChange = new BehaviorSubject('default');
        this.buttonClicked = new Subject();
        this.buttonClicked$ = this.buttonClicked.asObservable();
    }
    click(button) {
        this.buttonClicked.next(button);
    }
    setButtonLook(look) {
        this.buttonLookChange.next(look);
    }
};
KendoButtonService = __decorate([
    Injectable()
], KendoButtonService);

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-buttons',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620213799,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

const SPAN_TAG_NAME = 'SPAN';
/**
 * Represents the Kendo UI Button component for Angular.
 */
let ButtonDirective = class ButtonDirective {
    constructor(element, renderer, service, localization, ngZone) {
        this.service = service;
        this.ngZone = ngZone;
        /**
         * Provides visual styling that indicates if the Button is active.
         * By default, `toggleable` is set to `false`.
         */
        this.toggleable = false;
        /**
         * Adds visual weight to the Button and makes it primary.
         */
        this.primary = false;
        /**
         * Changes the visual appearance by using alternative styling options
         * ([more information and examples]({% slug appearance_button %})).
         *
         * The available values are:
         * * [`ButtonLook`]({% slug api_buttons_buttonlook %}) = `flat` | `outline`
         * * `clear`
         */
        this.look = 'default';
        /**
         * @hidden
         */
        this.role = 'button';
        /**
         * Fires each time the selected state of a toggleable button is changed.
         *
         * The event argument is the new selected state (boolean).
         */
        this.selectedChange = new EventEmitter();
        /**
         * Fires each time the user clicks the button.
         */
        this.click = new EventEmitter();
        this.isDisabled = false;
        this.isIcon = false;
        this.isIconClass = false;
        this._focused = false;
        this.domEvents = [];
        validatePackage(packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.localizationChangeSubscription = localization.changes
            .subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
        this.element = element.nativeElement;
        this.renderer = renderer;
    }
    /**
     * Backwards-compatible alias
     *
     * @hidden
     */
    get togglable() {
        return this.toggleable;
    }
    /**
     * @hidden
     */
    set togglable(value) {
        this.toggleable = value;
    }
    /**
     * Sets the selected state of the Button.
     */
    get selected() {
        return this._selected || false;
    }
    set selected(value) {
        this._selected = value;
    }
    /**
     * @hidden
     */
    set tabIndex(index) {
        this.element.tabIndex = index;
    }
    get tabIndex() {
        return this.element.tabIndex;
    }
    /**
     * Defines the name for an existing icon in a Kendo UI theme.
     * The icon is rendered inside the Button by a `span.k-icon` element.
     */
    set icon(icon) {
        if (icon) {
            this.iconSetter(icon, () => {
                this.isIcon = true;
                const classes = 'k-icon k-i-' + icon;
                this.addIcon(classes);
            });
        }
        else {
            this.isIcon = false;
            this.updateIconNode();
        }
    }
    /**
     * Defines a CSS class&mdash;or multiple classes separated by spaces&mdash;
     * which are applied to a `span` element inside the Button. Allows the usage of custom icons.
     */
    set iconClass(iconClassName) {
        if (iconClassName) {
            this.iconSetter(iconClassName, () => {
                this.isIconClass = true;
                this.addIcon(iconClassName);
            });
        }
        else {
            this.isIconClass = false;
            this.updateIconNode();
        }
    }
    /**
     * Defines a URL which is used for an `img` element inside the Button.
     * The URL can be relative or absolute. If relative, it is evaluated with relation to the web page URL.
     */
    set imageUrl(imageUrl) {
        if (imageUrl) {
            this.iconSetter(imageUrl, this.addImgIcon.bind(this));
        }
        else {
            this.removeImageNode();
        }
    }
    /**
     * If set to `true`, it disables the Button.
     */
    set disabled(disabled) {
        this.isDisabled = disabled;
        this.renderer.setProperty(this.element, 'disabled', disabled);
    }
    get disabled() {
        return this.isDisabled;
    }
    set isFocused(isFocused) {
        this.toggleClass('k-state-focused', isFocused);
        this._focused = isFocused;
    }
    get isFocused() {
        return this._focused;
    }
    get classButton() {
        return true;
    }
    get classPrimary() {
        return this.primary;
    }
    get isFlat() {
        return this.look === 'flat';
    }
    get isBare() {
        return this.look === 'bare';
    }
    get isOutline() {
        return this.look === 'outline';
    }
    get isClear() {
        return this.look === 'clear';
    }
    get roleSetter() {
        return this.role;
    }
    get classDisabled() {
        return this.isDisabled;
    }
    get classActive() {
        return this.selected;
    }
    get getDirection() {
        return this.direction;
    }
    /**
     * @hidden
     */
    onFocus() {
        this.isFocused = true;
    }
    /**
     * @hidden
     */
    onBlur() {
        this.isFocused = false;
    }
    ngOnInit() {
        const isSpan = this.element.tagName === SPAN_TAG_NAME;
        if (this.service) {
            this.buttonLookChangeSubscription = this.service.buttonLookChange
                .pipe(filter((look) => look !== 'default'))
                .subscribe((look) => this.look = look);
        }
        if (!this.element.hasAttribute('role') && this.togglable) {
            this.toggleAriaPressed(this.toggleable);
        }
        if (this.role) {
            this.setAttribute('role', this.role);
        }
        this.ngZone.runOutsideAngular(() => {
            this.domEvents.push(this.renderer.listen(this.element, 'click', this._onButtonClick.bind(this)));
            this.domEvents.push(this.renderer.listen(this.element, 'keydown', (event) => {
                if (isSpan && (event.keyCode === Keys.Space || event.keyCode === Keys.Enter)) {
                    this.click.emit(event);
                    this._onButtonClick();
                }
            }));
        });
    }
    ngOnChanges(change) {
        if (isChanged('togglable', change) || isChanged('toggleable', change)) {
            this.toggleAriaPressed(this.toggleable);
        }
    }
    ngAfterViewChecked() {
        this.setIconTextClasses();
    }
    ngOnDestroy() {
        this.imageNode = null;
        this.iconNode = null;
        this.renderer = null;
        this.localizationChangeSubscription.unsubscribe();
        if (this.service && this.buttonLookChangeSubscription) {
            this.buttonLookChangeSubscription.unsubscribe();
        }
        clearTimeout(this.deferTimeout);
        this.domEvents.forEach(unbindHandler => unbindHandler());
    }
    /**
     * Focuses the Button component.
     */
    focus() {
        if (isDocumentAvailable()) {
            this.element.focus();
            this.isFocused = true;
        }
    }
    /**
     * Blurs the Button component.
     */
    blur() {
        if (isDocumentAvailable()) {
            this.element.blur();
            this.isFocused = false;
        }
    }
    /**
     * @hidden
     */
    setAttribute(attribute, value) {
        this.renderer.setAttribute(this.element, attribute, value);
    }
    /**
     * @hidden
     */
    removeAttribute(attribute) {
        this.renderer.removeAttribute(this.element, attribute);
    }
    /**
     * @hidden
     *
     * Internal setter that triggers selectedChange
     */
    setSelected(value) {
        const changed = this.selected !== value;
        this.selected = value;
        this.setAttribute('aria-pressed', this.selected.toString());
        this.toggleClass('k-state-selected', this.selected);
        if (changed && hasObservers(this.selectedChange)) {
            this.ngZone.run(() => {
                this.selectedChange.emit(value);
            });
        }
    }
    toggleAriaPressed(shouldSet) {
        if (!isDocumentAvailable()) {
            return;
        }
        if (shouldSet) {
            this.setAttribute('aria-pressed', this.selected.toString());
        }
        else {
            this.removeAttribute('aria-pressed');
        }
    }
    hasText() {
        if (isDocumentAvailable()) {
            return String(this.element.textContent).trim().length > 0;
        }
        else {
            return false;
        }
    }
    addImgIcon(imageUrl) {
        let renderer = this.renderer;
        if (this.imageNode) {
            renderer.setProperty(this.imageNode, 'src', imageUrl);
        }
        else if (isDocumentAvailable()) {
            this.imageNode = renderer.createElement('img');
            renderer.setProperty(this.imageNode, 'src', imageUrl);
            renderer.setProperty(this.imageNode, 'className', 'k-image');
            renderer.setAttribute(this.imageNode, 'role', 'presentation');
            this.prependChild(this.imageNode);
        }
    }
    addIcon(classNames) {
        let renderer = this.renderer;
        if (this.iconNode) {
            renderer.setProperty(this.iconNode, 'className', classNames);
        }
        else if (isDocumentAvailable()) {
            this.iconNode = renderer.createElement('span');
            renderer.setProperty(this.iconNode, 'className', classNames);
            renderer.setAttribute(this.iconNode, 'role', 'presentation');
            this.prependChild(this.iconNode);
        }
    }
    prependChild(node) {
        this.defer(() => {
            if (this.renderer && node !== this.element.firstChild) {
                this.renderer.insertBefore(this.element, node, this.element.firstChild);
            }
        });
    }
    defer(callback) {
        this.ngZone.runOutsideAngular(() => {
            this.deferTimeout = setTimeout(callback, 0);
        });
    }
    iconSetter(icon, insertIcon) {
        if (icon) {
            insertIcon(icon);
        }
        this.setIconTextClasses();
    }
    removeImageNode() {
        if (this.imageNode && this.renderer.parentNode(this.imageNode)) {
            this.renderer.removeChild(this.element, this.imageNode);
            this.imageNode = null;
        }
    }
    removeIconNode() {
        if (this.iconNode && this.renderer.parentNode(this.iconNode)) {
            this.renderer.removeChild(this.element, this.iconNode);
            this.iconNode = null;
        }
    }
    updateIconNode() {
        if (!this.isIcon && !this.isIconClass) {
            this.removeIconNode();
        }
    }
    setIconTextClasses() {
        const hasIcon = this.isIcon || this.isIconClass || this.imageNode;
        const hasText = this.hasText();
        this.toggleClass('k-button-icon', hasIcon && !hasText);
        this.toggleClass('k-button-icontext', hasIcon && hasText);
    }
    toggleClass(className, add) {
        if (add) {
            this.renderer.addClass(this.element, className);
        }
        else {
            this.renderer.removeClass(this.element, className);
        }
    }
    _onButtonClick() {
        if (!this.disabled && this.service) {
            this.ngZone.run(() => {
                this.service.click(this);
            });
        }
        if (this.togglable && !this.service) {
            this.setSelected(!this.selected);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ButtonDirective.prototype, "toggleable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ButtonDirective.prototype, "togglable", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ButtonDirective.prototype, "primary", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ButtonDirective.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ButtonDirective.prototype, "selected", null);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], ButtonDirective.prototype, "tabIndex", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ButtonDirective.prototype, "icon", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ButtonDirective.prototype, "iconClass", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], ButtonDirective.prototype, "imageUrl", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ButtonDirective.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ButtonDirective.prototype, "role", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ButtonDirective.prototype, "selectedChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ButtonDirective.prototype, "click", void 0);
__decorate([
    HostBinding('class.k-button'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "classButton", null);
__decorate([
    HostBinding('class.k-primary'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "classPrimary", null);
__decorate([
    HostBinding('class.k-flat'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "isFlat", null);
__decorate([
    HostBinding('class.k-bare'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "isBare", null);
__decorate([
    HostBinding('class.k-outline'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "isOutline", null);
__decorate([
    HostBinding('class.k-button-clear'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "isClear", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "roleSetter", null);
__decorate([
    HostBinding('attr.aria-disabled'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "classDisabled", null);
__decorate([
    HostBinding('class.k-state-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "classActive", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ButtonDirective.prototype, "getDirection", null);
__decorate([
    HostListener('focus'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ButtonDirective.prototype, "onFocus", null);
__decorate([
    HostListener('blur'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ButtonDirective.prototype, "onBlur", null);
ButtonDirective = __decorate([
    Directive({
        exportAs: 'kendoButton',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.button'
            }
        ],
        selector: 'button[kendoButton], span[kendoButton]' // tslint:disable-line
    }),
    __param(2, Optional()),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        KendoButtonService,
        LocalizationService,
        NgZone])
], ButtonDirective);

/* tslint:disable:no-null-keyword */
/* tslint:disable:no-bitwise */
const resolvedPromise = Promise.resolve(null);
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const tick = (f) => (resolvedPromise.then(f));
/**
 * @hidden
 */
function isDocumentNode(container) {
    return container.nodeType === 9;
}
/**
 * @hidden
 */
function closest(element, selector) {
    if (element.closest) {
        return element.closest(selector);
    }
    const matches = Element.prototype.matches ?
        (el, sel) => el.matches(sel)
        : (el, sel) => el.msMatchesSelector(sel);
    let node = element;
    while (node && !isDocumentNode(node)) {
        if (matches(node, selector)) {
            return node;
        }
        node = node.parentNode;
    }
}

/**
 * @hidden
 */
class PreventableEvent {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * @hidden
 */
const tabindex = 'tabindex';
/**
 * Represents the Kendo UI ButtonGroup component for Angular.
 */
let ButtonGroupComponent = class ButtonGroupComponent {
    constructor(service, localization, element) {
        this.service = service;
        this.element = element;
        /**
         * By default, the selection mode of the ButtonGroup is set to `multiple`.
         */
        this.selection = 'multiple';
        /**
         * Changes the visual appearance by using alternative styling options
         * ([more information and examples]({% slug styling_buttongroup %})).
         * The `look` property of the ButtonGroup takes precedence over the `look` property
         * of the individual buttons that are part of the group.
         *
         * The available values are:
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Fires every time keyboard navigation occurs.
         */
        this.navigate = new EventEmitter();
        this._tabIndex = 0;
        this.currentTabIndex = 0;
        this.localizationChangeSubscription = localization.changes.subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
    }
    /**
     * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
     */
    set tabIndex(value) {
        this._tabIndex = value;
        this.currentTabIndex = value;
    }
    get tabIndex() {
        return this._tabIndex;
    }
    get wrapperClass() {
        return true;
    }
    get disabledClass() {
        return this.disabled;
    }
    get stretchedClass() {
        return !!this.width;
    }
    get isFlat() {
        return this.look === 'flat';
    }
    get isBare() {
        return this.look === 'bare';
    }
    get isOutline() {
        return this.look === 'outline';
    }
    get getRole() {
        return this.isSelectionSingle() ? 'radiogroup' : 'group';
    }
    get dir() {
        return this.direction;
    }
    get ariaDisabled() {
        return this.disabled;
    }
    get wrapperWidth() {
        return this.width;
    }
    get wrapperTabIndex() {
        return this.disabled ? undefined : this.currentTabIndex;
    }
    /**
     * @hidden
     */
    keydown(event) {
        if (!this.disabled) {
            this.navigateFocus(event);
        }
    }
    /**
     * @hidden
     */
    onFocus() {
        this.currentTabIndex = -1;
        const focusedIndex = this.buttons.toArray().findIndex(current => current.element.tabIndex !== -1);
        const index = focusedIndex === -1 ? 0 : focusedIndex;
        this.focus(this.buttons.filter((_current, i) => {
            return i === index;
        }));
    }
    /**
     * @hidden
     */
    focusout(event) {
        if (event.relatedTarget && event.relatedTarget.parentNode !== this.element.nativeElement) {
            this.defocus(this.buttons.toArray());
            this.currentTabIndex = this.tabIndex;
        }
    }
    ngOnInit() {
        this.service.setButtonLook(this.look);
        this.subscription = this.service.buttonClicked$.subscribe((button) => {
            let newSelectionValue;
            if (this.isSelectionSingle()) {
                newSelectionValue = true;
                this.deactivate(this.buttons.filter(current => current !== button));
            }
            else {
                this.defocus(this.buttons.toArray());
                newSelectionValue = !button.selected;
            }
            if (button.togglable) {
                button.setSelected(newSelectionValue);
            }
            button.setAttribute(tabindex, "0");
        });
    }
    ngOnChanges(change) {
        if (isChanged('disabled', change)) {
            this.buttons.forEach((button) => {
                if (isPresent(this.disabled)) {
                    button.disabled = this.disabled;
                }
            });
        }
    }
    ngAfterContentInit() {
        this.buttons.forEach((button) => {
            if (button.selected) {
                button.setAttribute(tabindex, "0");
            }
            else {
                button.setAttribute(tabindex, "-1");
            }
        });
    }
    ngAfterViewChecked() {
        if (this.buttons.length) {
            this.buttons.first.renderer.addClass(this.buttons.first.element, 'k-group-start');
            this.buttons.last.renderer.addClass(this.buttons.last.element, 'k-group-end');
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.localizationChangeSubscription.unsubscribe();
    }
    ngAfterContentChecked() {
        this.verifySettings();
    }
    navigateFocus(event) {
        let focusedIndex = this.buttons.toArray().findIndex(current => current.element.tabIndex !== -1);
        const firstIndex = 0;
        const lastIndex = this.buttons.length - 1;
        const eventArgs = new PreventableEvent();
        if (event.keyCode === Keys.ArrowRight && focusedIndex < lastIndex) {
            this.navigate.emit(eventArgs);
            if (!eventArgs.isDefaultPrevented()) {
                this.defocus(this.buttons.toArray());
                this.focus(this.buttons.filter((_current, index) => {
                    return index === focusedIndex + 1;
                }));
            }
        }
        if (event.keyCode === Keys.ArrowLeft && focusedIndex > firstIndex) {
            this.navigate.emit(eventArgs);
            if (!eventArgs.isDefaultPrevented()) {
                this.defocus(this.buttons.toArray());
                this.focus(this.buttons.filter((_current, index) => {
                    return index === focusedIndex - 1;
                }));
            }
        }
    }
    deactivate(buttons) {
        buttons.forEach((button) => {
            button.setSelected(false);
            button.setAttribute(tabindex, "-1");
        });
    }
    activate(buttons) {
        buttons.forEach((button) => {
            button.setSelected(true);
            button.setAttribute(tabindex, "0");
            button.focus();
        });
    }
    defocus(buttons) {
        buttons.forEach((button) => {
            button.setAttribute(tabindex, "-1");
        });
    }
    focus(buttons) {
        buttons.forEach((button) => {
            button.setAttribute(tabindex, "0");
            button.focus();
        });
    }
    verifySettings() {
        if (isDevMode()) {
            if (this.isSelectionSingle() && this.buttons.filter(button => button.selected).length > 1) {
                throw new Error('Having multiple selected buttons with single selection mode is not supported');
            }
        }
    }
    isSelectionSingle() {
        return this.selection === 'single';
    }
};
__decorate([
    Input('disabled'),
    __metadata("design:type", Boolean)
], ButtonGroupComponent.prototype, "disabled", void 0);
__decorate([
    Input('selection'),
    __metadata("design:type", String)
], ButtonGroupComponent.prototype, "selection", void 0);
__decorate([
    Input('width'),
    __metadata("design:type", String)
], ButtonGroupComponent.prototype, "width", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ButtonGroupComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], ButtonGroupComponent.prototype, "tabIndex", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ButtonGroupComponent.prototype, "navigate", void 0);
__decorate([
    ContentChildren(ButtonDirective),
    __metadata("design:type", QueryList)
], ButtonGroupComponent.prototype, "buttons", void 0);
__decorate([
    HostBinding('class.k-button-group'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "wrapperClass", null);
__decorate([
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('class.k-button-group-stretched'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "stretchedClass", null);
__decorate([
    HostBinding('class.k-button-group-flat'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "isFlat", null);
__decorate([
    HostBinding('class.k-button-group-bare'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "isBare", null);
__decorate([
    HostBinding('class.k-button-group-outline'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "isOutline", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "getRole", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "dir", null);
__decorate([
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "ariaDisabled", null);
__decorate([
    HostBinding('style.width'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "wrapperWidth", null);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], ButtonGroupComponent.prototype, "wrapperTabIndex", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ButtonGroupComponent.prototype, "keydown", null);
__decorate([
    HostListener('focus'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ButtonGroupComponent.prototype, "onFocus", null);
__decorate([
    HostListener('focusout', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ButtonGroupComponent.prototype, "focusout", null);
ButtonGroupComponent = __decorate([
    Component({
        exportAs: 'kendoButtonGroup',
        providers: [
            KendoButtonService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.buttongroup'
            }
        ],
        selector: 'kendo-buttongroup',
        template: `
        <ng-content select="[kendoButton]"></ng-content>
    `
    }),
    __metadata("design:paramtypes", [KendoButtonService,
        LocalizationService,
        ElementRef])
], ButtonGroupComponent);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmodules'] }})
 * definition for the Button directive.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Button module
 * import { ButtonModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ButtonModule], // import Button module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let ButtonModule = class ButtonModule {
};
ButtonModule = __decorate([
    NgModule({
        declarations: [ButtonDirective],
        exports: [ButtonDirective]
    })
], ButtonModule);

/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `ButtonGroupComponent`&mdash;The ButtonGroupComponent component class.
 */
let ButtonGroupModule = class ButtonGroupModule {
};
ButtonGroupModule = __decorate([
    NgModule({
        declarations: [ButtonGroupComponent],
        exports: [ButtonGroupComponent],
        imports: [CommonModule, ButtonModule]
    })
], ButtonGroupModule);

/**
 * Used for rendering the list item content.
 *
 * To define the item template, nest a `<ng-template>` tag with the `kendo<ComponentName>ItemTemplate` directive inside the component tag.
 *
 * For the DropDownButton, use the `kendoDropDownButtonItemTemplate` directive.
 * For the SplitButton, use the `kendoSplitButtonItemTemplate` directive.
 *
 * The template context is set to the current component. To get a reference to the current data item, use the `let-dataItem` directive.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-splitbutton [data]="listItems">
 *    <ng-template kendoSplitButtonItemTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-splitbutton>
 *  <kendo-dropdownbutton [data]="listItems">
 *    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-dropdownbutton>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<any> = [{
 *      text: 'item1',
 *      icon: 'refresh',
 *      disabled: false,
 *      click: (dataItem: any) => {
 *          //action
 *      }
 *  }, {
 *      text: 'item2',
 *      icon: 'refresh',
 *      disabled: false,
 *      click: (dataItem: any) => {
 *          //action
 *      }
 *  }]
 * }
 * ```
 *
 * For more examples, refer to the article on the [DropDownList templates]({% slug overview_ddl %}#templates).
 */
let ButtonItemTemplateDirective = class ButtonItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ButtonItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDropDownButtonItemTemplate],[kendoSplitButtonItemTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ButtonItemTemplateDirective);

/**
 * @hidden
 */
let ListComponent = class ListComponent {
    constructor() {
        this.onItemClick = new EventEmitter();
        this.onItemBlur = new EventEmitter();
        validatePackage(packageMetadata);
    }
    getText(dataItem) {
        if (dataItem) {
            return this.textField ? dataItem[this.textField] : dataItem.text || dataItem;
        }
        return undefined;
    }
    getIconClasses(dataItem) {
        const icon = dataItem.icon ? 'k-icon k-i-' + dataItem.icon : undefined;
        const classes = {};
        classes[icon || dataItem.iconClass] = true;
        return classes;
    }
    onClick(index) {
        this.onItemClick.emit(index);
    }
    onBlur() {
        this.onItemBlur.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], ListComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", ButtonItemTemplateDirective)
], ListComponent.prototype, "itemTemplate", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListComponent.prototype, "onItemClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListComponent.prototype, "onItemBlur", void 0);
ListComponent = __decorate([
    Component({
        selector: 'kendo-button-list',
        template: `
        <ul class="k-list k-reset" unselectable="on">
            <li role="menuitem" unselectable="on" tabindex="-1"
                kendoButtonFocusable
                *ngFor="let dataItem of data; let index = index;"
                [index]="index"
                [ngClass]="{'k-item': true, 'k-state-disabled': dataItem.disabled}"
                (click)="onClick(index)"
                (blur)="onBlur()"
                [attr.aria-disabled]="dataItem.disabled ? true : false">
                <ng-template *ngIf="itemTemplate?.templateRef"
                    [templateContext]="{
                        templateRef: itemTemplate?.templateRef,
                        $implicit: dataItem
                    }">
                </ng-template>
                <ng-template [ngIf]="!itemTemplate?.templateRef">
                    <span
                        *ngIf="dataItem.icon || dataItem.iconClass"
                        [ngClass]="getIconClasses(dataItem)"
                    ></span>
                    <img
                        *ngIf="dataItem.imageUrl"
                        class="k-image"
                        [src]="dataItem.imageUrl"
                        alt=""
                    >
                    {{ getText(dataItem) }}
                </ng-template>
            </li>
        </ul>
      `
    }),
    __metadata("design:paramtypes", [])
], ListComponent);

/**
 * @hidden
 */
let FocusService = class FocusService {
    /**
     * @hidden
     */
    constructor() {
        this.onFocus = new EventEmitter();
    }
    isFocused(index) {
        return index === this.focused;
    }
    focus(index) {
        if (this.isFocused(index)) {
            return;
        }
        this.focused = index;
        this.onFocus.emit(index);
    }
    resetFocus() {
        this.focused = -1;
    }
    get focused() {
        return this.focusedIndex;
    }
    set focused(index) {
        this.focusedIndex = index;
        this.onFocus.emit(index);
    }
};
FocusService = __decorate([
    Injectable()
], FocusService);

/**
 * @hidden
 */
let FocusableDirective = class FocusableDirective {
    constructor(focusService, elementRef, renderer) {
        this.focusService = focusService;
        this.renderer = renderer;
        this.element = elementRef.nativeElement;
        this.subscribeEvents();
    }
    ngOnInit() {
        if (this.index === this.focusService.focused) {
            this.renderer.addClass(this.element, 'k-state-focused');
        }
        else {
            this.renderer.removeClass(this.element, 'k-state-focused');
        }
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        this.unsubscribeEvents();
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.focusSubscription = this.focusService.onFocus.subscribe((index) => {
            if (this.index === index) {
                this.renderer.addClass(this.element, 'k-state-focused');
                this.element.focus();
            }
            else {
                this.renderer.removeClass(this.element, 'k-state-focused');
            }
        });
    }
    unsubscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        if (this.focusSubscription) {
            this.focusSubscription.unsubscribe();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], FocusableDirective.prototype, "index", void 0);
FocusableDirective = __decorate([
    Directive({
        selector: '[kendoButtonFocusable]'
    }),
    __metadata("design:paramtypes", [FocusService, ElementRef, Renderer2])
], FocusableDirective);

/**
 * @hidden
 */
let TemplateContextDirective = class TemplateContextDirective {
    constructor(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    set templateContext(context) {
        if (this.insertedViewRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.insertedViewRef));
            this.insertedViewRef = undefined;
        }
        if (context.templateRef) {
            this.insertedViewRef = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], TemplateContextDirective.prototype, "templateContext", null);
TemplateContextDirective = __decorate([
    Directive({
        selector: '[templateContext]' // tslint:disable-line:directive-selector
    }),
    __metadata("design:paramtypes", [ViewContainerRef])
], TemplateContextDirective);

const EXPORTED_DIRECTIVES = [
    ListComponent,
    FocusableDirective,
    ButtonItemTemplateDirective,
    TemplateContextDirective
];
/**
 * @hidden
 */
let ListModule = class ListModule {
};
ListModule = __decorate([
    NgModule({
        declarations: [EXPORTED_DIRECTIVES],
        exports: [EXPORTED_DIRECTIVES],
        imports: [CommonModule]
    })
], ListModule);

/**
 * @hidden
 */
var KeyEvents;
(function (KeyEvents) {
    KeyEvents[KeyEvents["keydown"] = 0] = "keydown";
    KeyEvents[KeyEvents["keypress"] = 1] = "keypress";
    KeyEvents[KeyEvents["keyup"] = 2] = "keyup";
})(KeyEvents || (KeyEvents = {}));

/**
 * @hidden
 */
var NavigationAction;
(function (NavigationAction) {
    NavigationAction[NavigationAction["Undefined"] = 0] = "Undefined";
    NavigationAction[NavigationAction["Open"] = 1] = "Open";
    NavigationAction[NavigationAction["Close"] = 2] = "Close";
    NavigationAction[NavigationAction["Enter"] = 3] = "Enter";
    NavigationAction[NavigationAction["EnterPress"] = 4] = "EnterPress";
    NavigationAction[NavigationAction["EnterUp"] = 5] = "EnterUp";
    NavigationAction[NavigationAction["Tab"] = 6] = "Tab";
    NavigationAction[NavigationAction["Esc"] = 7] = "Esc";
    NavigationAction[NavigationAction["Navigate"] = 8] = "Navigate";
})(NavigationAction || (NavigationAction = {}));

/* tslint:disable:deprecation */
/**
 * @hidden
 */
class ListButton {
    constructor(focusService, navigationService, wrapperRef, _zone, localization, cdr) {
        this.focusService = focusService;
        this.navigationService = navigationService;
        this.wrapperRef = wrapperRef;
        this._zone = _zone;
        this.cdr = cdr;
        this._open = false;
        this._disabled = false;
        this._active = false;
        this._popupSettings = { animate: true, popupClass: '' };
        this.listId = guid();
        this._isFocused = false;
        this.focusService = focusService;
        this.navigationService = navigationService;
        this.wrapper = wrapperRef.nativeElement;
        this.localizationChangeSubscription = localization.changes.subscribe(({ rtl }) => (this.direction = rtl ? 'rtl' : 'ltr'));
        this.subscribeEvents();
    }
    get popupClasses() {
        const popupClasses = ['k-list-container', 'k-reset', 'k-group'];
        if (this._popupSettings.popupClass) {
            popupClasses.push(this._popupSettings.popupClass);
        }
        return popupClasses.join(' ');
    }
    get openState() {
        return this._open;
    }
    set openState(open) {
        this._open = open;
    }
    /**
     * @hidden
     */
    togglePopupVisibility() {
        if (this._disabled) {
            return;
        }
        this.openState = !this.openState;
        if (!this.openState) {
            this.focusService.focus(-1);
        }
    }
    /**
     * @hidden
     */
    onItemClick(index) {
        this.emitItemClickHandler(index);
        setTimeout(() => this.focusWrapper(), 1);
    }
    ngOnDestroy() {
        this.openState = false;
        this.unsubscribeEvents();
        clearTimeout(this.focusFirstTimeout);
        clearTimeout(this.blurTimeout);
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }
    subscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.subscribeListItemFocusEvent();
        this.subscribeComponentBlurredEvent();
        this.subscribeNavigationEvents();
    }
    subscribeListItemFocusEvent() {
        this.focusSubscription = this.focusService.onFocus.subscribe(() => {
            this._isFocused = true;
        });
    }
    subscribeComponentBlurredEvent() {
        this._zone.runOutsideAngular(() => {
            this.tabSubscription = this.navigationService.tab.pipe(filter(() => this._isFocused)).subscribe(this.handleTab.bind(this));
            this.componentBlurredSubscription = fromEvent(document, 'click')
                .pipe(filter((event) => !this.wrapperContains(event.target)), filter(() => this._isFocused))
                .subscribe(() => this._zone.run(() => this.blurWrapper()));
        });
    }
    subscribeNavigationEvents() {
        this.navigationSubscription = this.navigationService.navigate.subscribe(this.focusService.focus.bind(this.focusService));
        this.enterUpSubscription = this.navigationService.enterup.subscribe(() => {
            this.enterHandler();
            this.focusWrapper();
        });
        this.openSubscription = this.navigationService.open.subscribe(() => {
            if (!this._open) {
                this.togglePopupVisibility();
                this.focusFirstItem();
            }
            else {
                this.focusWrapper();
            }
        });
        this.closeSubscription = merge(this.navigationService.close, this.navigationService.esc).subscribe(() => this.focusWrapper());
    }
    enterHandler() { } // tslint:disable-line
    unsubscribeEvents() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.unsubscribe(this.componentBlurredSubscription);
        this.unsubscribe(this.focusSubscription);
        this.unsubscribe(this.navigationSubscription);
        this.unsubscribe(this.enterPressSubscription);
        this.unsubscribe(this.enterUpSubscription);
        this.unsubscribe(this.openSubscription);
        this.unsubscribe(this.closeSubscription);
        this.unsubscribe(this.tabSubscription);
    }
    unsubscribe(subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    keyDownHandler(event) {
        this.keyHandler(event);
    }
    /**
     * @hidden
     */
    keyPressHandler(event) {
        this.keyHandler(event, KeyEvents.keypress);
    }
    /**
     * @hidden
     */
    keyUpHandler(event) {
        this.keyHandler(event, KeyEvents.keyup);
    }
    /**
     * @hidden
     */
    keyHandler(event, keyEvent) {
        if (this._disabled) {
            return;
        }
        let focused = this.focusService.focused || 0;
        const eventData = event;
        const action = this.navigationService.process({
            altKey: eventData.altKey,
            current: focused,
            keyCode: eventData.keyCode,
            keyEvent: keyEvent,
            max: this._data ? this._data.length - 1 : 0,
            min: 0
        });
        if (action !== NavigationAction.Undefined &&
            action !== NavigationAction.Tab &&
            (action !== NavigationAction.Enter || (action === NavigationAction.Enter && this._open))) {
            if (event.keyCode === Keys.Space && action === NavigationAction.EnterUp) {
                this._open = false;
            }
            else {
                eventData.preventDefault();
            }
        }
    }
    emitItemClickHandler(index) {
        const dataItem = this._data[index];
        if (this._itemClick) {
            this._itemClick.emit(dataItem);
        }
        if (dataItem && dataItem.click && !dataItem.disabled) {
            dataItem.click(dataItem);
        }
    }
    focusFirstItem() {
        if (this._data && isPresent(this._data[0])) {
            this.focusFirstTimeout = setTimeout(() => this.focusService.focus(0), 1);
        }
    }
    focusWrapper() {
        if (this._open) {
            this.togglePopupVisibility();
            this.focusButton();
        }
    }
    wrapperContains(element) {
        return this.wrapper === element || this.wrapper.contains(element);
    }
    blurWrapper(emit = true) {
        if (this._open) {
            this.togglePopupVisibility();
        }
        this._isFocused = false;
        if (emit) {
            this._blur.emit();
            this.cdr.markForCheck();
        }
    }
    focusButton() {
        if (this.button) {
            this.button.nativeElement.focus();
        }
    }
    handleTab() {
        this.focusButton();
        this.blurWrapper(false);
    }
}

/**
 * @hidden
 */
const NAVIGATION_CONFIG = new InjectionToken('navigation.config');

/**
 * @hidden
 */
let NavigationService = class NavigationService {
    constructor(config) {
        this.navigate = new EventEmitter();
        this.open = new EventEmitter();
        this.close = new EventEmitter();
        this.enter = new EventEmitter();
        this.enterpress = new EventEmitter();
        this.enterup = new EventEmitter();
        this.tab = new EventEmitter();
        this.esc = new EventEmitter();
        this.useLeftRightArrows = config.useLeftRightArrows;
    }
    process(args) {
        const keyCode = args.keyCode;
        const keyEvent = args.keyEvent;
        let index;
        let action = NavigationAction.Undefined;
        if (keyEvent === KeyEvents.keypress) {
            if (this.isEnter(keyCode)) {
                action = NavigationAction.EnterPress;
            }
        }
        else if (keyEvent === KeyEvents.keyup) {
            if (this.isEnter(keyCode)) {
                action = NavigationAction.EnterUp;
            }
        }
        else {
            if (args.altKey && keyCode === Keys.ArrowDown) {
                action = NavigationAction.Open;
            }
            else if (args.altKey && keyCode === Keys.ArrowUp) {
                action = NavigationAction.Close;
            }
            else if (this.isEnter(keyCode)) {
                action = NavigationAction.Enter;
            }
            else if (keyCode === Keys.Escape) {
                action = NavigationAction.Esc;
            }
            else if (keyCode === Keys.Tab) {
                action = NavigationAction.Tab;
            }
            else if (keyCode === Keys.ArrowUp || (this.useLeftRightArrows && keyCode === Keys.ArrowLeft)) {
                const step = args.flipNavigation ? 1 : -1;
                const start = args.flipNavigation ? args.min : args.max;
                const end = args.flipNavigation ? args.max : args.min;
                index = this.next({
                    current: args.current,
                    start: start,
                    end: end,
                    step: step
                });
                action = NavigationAction.Navigate;
            }
            else if (keyCode === Keys.ArrowDown || (this.useLeftRightArrows && keyCode === Keys.ArrowRight)) {
                const step = args.flipNavigation ? -1 : 1;
                const start = args.flipNavigation ? args.max : args.min;
                const end = args.flipNavigation ? args.min : args.max;
                index = this.next({
                    current: args.current,
                    start: start,
                    end: end,
                    step: step
                });
                action = NavigationAction.Navigate;
            }
            else if (keyCode === Keys.Home) {
                index = args.min;
                action = NavigationAction.Navigate;
            }
            else if (keyCode === Keys.End) {
                index = args.max;
                action = NavigationAction.Navigate;
            }
        }
        if (action !== NavigationAction.Undefined) {
            this[NavigationAction[action].toLowerCase()].emit(index);
        }
        return action;
    }
    isEnter(keyCode) {
        return keyCode === Keys.Enter || keyCode === Keys.Space;
    }
    next(args) {
        if (!isPresent(args.current)) {
            return args.start;
        }
        else {
            return args.current !== args.end ? args.current + args.step : args.end;
        }
    }
};
NavigationService = __decorate([
    Injectable(),
    __param(0, Inject(NAVIGATION_CONFIG)),
    __metadata("design:paramtypes", [Object])
], NavigationService);

const NAVIGATION_SETTINGS = {
    useLeftRightArrows: true
};
const ɵ0 = NAVIGATION_SETTINGS;
const NAVIGATION_SETTINGS_PROVIDER = {
    provide: NAVIGATION_CONFIG,
    useValue: ɵ0
};
/**
 * Represents the Kendo UI SplitButton component for Angular.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-splitbutton [data]="data" icon="paste"
 *      (itemClick)="onSplitButtonItemClick($event)"
 *      (buttonClick)="onSplitButtonClick()">Paste</kendo-splitbutton>
 * `
 * })
 *
 * class AppComponent {
 *   public data: Array<any> = [{
 *       text: 'Keep Text Only',
 *       icon: 'paste-plain-text',
 *       click: () => { console.log('Keep Text Only click handler'); }
 *   }, {
 *       text: 'Paste as HTML',
 *       icon: 'paste-as-html'
 *   }, {
 *       text: 'Paste Markdown',
 *       icon: 'paste-markdown'
 *   }, {
 *       text: 'Set Default Paste'
 *   }];
 *
 *   public onSplitButtonClick(dataItem: any): void {
 *       console.log('Paste');
 *   }
 *
 *   public onSplitButtonItemClick(dataItem: any): void {
 *       if (dataItem) {
 *           console.log(dataItem.text);
 *       }
 *   }
 * }
 * ```
 */
let SplitButtonComponent = class SplitButtonComponent extends ListButton {
    constructor(focusService, navigationService, wrapperRef, zone, popupService, elRef, localization, cdr) {
        super(focusService, navigationService, wrapperRef, zone, localization, cdr);
        this.popupService = popupService;
        this.elRef = elRef;
        /**
         * Sets the text of the SplitButton.
         */
        this.text = '';
        /**
         * Defines an icon to be rendered next to the button text
         * ([see example]({% slug databinding_splitbutton %}#toc-arrays-of-complex-data)).
         */
        this.icon = '';
        /**
         * Defines an icon with a custom CSS class to be rendered next to the button text
         * ([see example]({% slug databinding_splitbutton %}#toc-arrays-of-complex-data)).
         */
        this.iconClass = '';
        /**
         * Defines the location of an image to be displayed next to the button text
         * ([see example]({% slug databinding_splitbutton %}#toc-arrays-of-complex-data)).
         */
        this.imageUrl = '';
        /**
         * Changes the visual appearance by using alternative styling options.
         *
         * The available values are:
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabIndex = 0;
        /**
         * Specifies the name of the [font icon]({% slug icons %}#toc-list-of-font-icons) that will
         * be rendered for the button which opens the popup.
         */
        this.arrowButtonIcon = 'arrow-s';
        /**
         * Fires each time the user clicks the main button.
         *
         * @example
         * ```ts
         * _@Component({
         *    selector: 'my-app',
         *    template: `
         *        <kendo-splitbutton (buttonClick)="onSplitButtonClick()" [data]="data">
         *            Reply
         *        </kendo-splitbutton>
         *    `
         * })
         * class AppComponent {
         *    public data: Array<any> = ['Reply All', 'Forward', 'Reply & Delete'];
         *
         *    public onSplitButtonClick(): void {
         *      console.log('SplitButton click');
         *    }
         * }
         * ```
         *
         */
        this.buttonClick = new EventEmitter();
        /**
         * Fires each time the user clicks on the drop-down list. The event data contains the data item bound to the clicked list item.
         *
         * @example
         * ```ts
         * _@Component({
         *     selector: 'my-app',
         *    template: `
         *        <kendo-splitbutton (itemClick)="onSplitButtonItemClick($event)" [data]="data">
         *          Reply
         *      </kendo-splitbutton>
         *    `
         * })
         * class AppComponent {
         *    public data: Array<any> = ['Reply All', 'Forward', 'Reply & Delete'];
         *
         *   public onSplitButtonItemClick(dataItem?: string): void {
         *        if (dataItem) {
         *            console.log(dataItem);
         *       }
         *    }
         * }
         * ```
         *
         */
        this.itemClick = new EventEmitter();
        /**
         * Fires each time the SplitButton gets focused.
         */
        this.onFocus = new EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the SplitButton gets blurred.
         */
        this.onBlur = new EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open.
         */
        this.close = new EventEmitter();
        this.listId = guid();
        /**
         * @hidden
         */
        this.activeArrow = false;
        this.buttonText = '';
        this.lockFocus = false;
        this._itemClick = this.itemClick;
        this._blur = this.onBlur;
    }
    /**
     * When set to `true`, disables a SplitButton item
     * ([see example]({% slug databinding_splitbutton %}#toc-arrays-of-complex-data)).
     */
    set disabled(value) {
        this._disabled = value;
    }
    get disabled() {
        return this._disabled;
    }
    /**
     * Configures the popup of the SplitButton.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     * - `align: "left" | "center" | "right"`&mdash;Specifies the alignment of the popup.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true, popupClass: '' }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Sets the data of the SplitButton.
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this._data = data || [];
    }
    get data() {
        if (!this._data) {
            this.data = [];
        }
        return this._data;
    }
    /**
     * @hidden
     */
    set openState(open) {
        if (this.disabled) {
            return;
        }
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        if (eventArgs.isDefaultPrevented()) {
            return;
        }
        this._toggle(open);
    }
    /**
     * @hidden
     */
    get openState() {
        return this._open;
    }
    /**
     * @hidden
     */
    get active() {
        return this._active;
    }
    /**
     * @hidden
     */
    get componentTabIndex() {
        return this.disabled ? -1 : this.tabIndex;
    }
    set isFocused(value) {
        this._isFocused = value;
    }
    get isFocused() {
        return this._isFocused && !this._disabled;
    }
    get widgetClasses() {
        return true;
    }
    get dir() {
        return this.direction;
    }
    /**
     * @hidden
     */
    get ariaLabel() {
        return `${this.buttonText} splitbutton`;
    }
    /**
     * @hidden
     */
    onButtonFocus() {
        if (!this.isFocused) {
            this._isFocused = true;
            this.onFocus.emit();
        }
    }
    /**
     * @hidden
     */
    onArrowButtonClick() {
        this.lockFocus = true;
        this.togglePopupVisibility();
    }
    /**
     * @hidden
     */
    toggleButtonActiveState(enable) {
        this._active = enable;
    }
    /**
     * @hidden
     */
    toggleArrowButtonActiveState(enable) {
        this.activeArrow = enable;
    }
    /**
     * @hidden
     */
    onButtonClick() {
        this.lockFocus = true;
        this.buttonClick.emit();
    }
    /**
     * @hidden
     */
    onButtonBlur() {
        if (!this.isOpen && !this.lockFocus) {
            this.blurWrapper();
        }
        this.lockFocus = false;
    }
    /**
     * @hidden
     */
    keydown(event) {
        this.keyDownHandler(event);
        if (event.keyCode === Keys.Space) {
            this._active = true;
        }
    }
    /**
     * @hidden
     */
    keypress(event) {
        this.keyPressHandler(event);
    }
    /**
     * @hidden
     */
    keyup(event) {
        this._active = false;
        if (event.keyCode !== Keys.Space) {
            this.keyUpHandler(event);
        }
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        this.updateButtonText();
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        if (changes.hasOwnProperty('text')) {
            this.updateButtonText();
        }
    }
    /**
     * @hidden
     */
    togglePopupVisibility() {
        super.togglePopupVisibility();
        if (isDocumentAvailable() && this.lockFocus) {
            this.button.nativeElement.focus();
        }
    }
    /**
     * @hidden
     */
    wrapperContains(element) {
        return (this.wrapper === element || this.wrapper.contains(element) || (this.popupRef && this.popupRef.popupElement.contains(element)));
    }
    /**
     * @hidden
     */
    get anchorAlign() {
        let align = { horizontal: this.popupSettings.align || 'left', vertical: 'bottom' };
        if (this.direction === 'rtl' && !isPresent(this.popupSettings.align)) {
            align.horizontal = 'right';
        }
        return align;
    }
    /**
     * @hidden
     */
    get popupAlign() {
        let align = { horizontal: this.popupSettings.align || 'left', vertical: 'top' };
        if (this.direction === 'rtl' && !isPresent(this.popupSettings.align)) {
            align.horizontal = 'right';
        }
        return align;
    }
    /**
     * Focuses the SplitButton component.
     */
    focus() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    }
    /**
     * Blurs the SplitButton component.
     */
    blur() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.blur();
            this.blurWrapper();
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyPopup();
    }
    /**
     * Toggles the visibility of the popup.
     * If the `toggle` method is used to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        if (this.disabled) {
            return;
        }
        tick(() => this._toggle(open === undefined ? !this._open : open));
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this.openState;
    }
    enterHandler() {
        if (this.disabled) {
            return;
        }
        if (this.openState) {
            let focused = this.focusService.focused;
            if (isPresent(focused) && focused !== -1) {
                this.emitItemClickHandler(focused);
            }
        }
        else {
            this.buttonClick.emit();
        }
    }
    updateButtonText() {
        if (isDocumentAvailable()) {
            let innerText = this.wrapper.innerText
                .split('\n')
                .join('')
                .trim();
            //setTimout is needed because of `Expression has changed after it was checked.` error;
            setTimeout(() => (this.buttonText = innerText), 0);
        }
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.containerRef : appendTo;
    }
    _toggle(open) {
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    createPopup() {
        this.popupRef = this.popupService.open({
            anchor: this.elRef,
            anchorAlign: this.anchorAlign,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupAlign: this.popupAlign,
            popupClass: this.popupClasses
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => (this.openState = false));
        this.popupRef.popupOpen.subscribe(this.focusFirstItem.bind(this));
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "imageUrl", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], SplitButtonComponent.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], SplitButtonComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], SplitButtonComponent.prototype, "tabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], SplitButtonComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SplitButtonComponent.prototype, "buttonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SplitButtonComponent.prototype, "arrowButtonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitButtonComponent.prototype, "arrowButtonIcon", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "buttonClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "itemClick", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "onBlur", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitButtonComponent.prototype, "close", void 0);
__decorate([
    ContentChild(ButtonItemTemplateDirective, { static: false }),
    __metadata("design:type", ButtonItemTemplateDirective)
], SplitButtonComponent.prototype, "itemTemplate", void 0);
__decorate([
    ViewChild('button', { static: true }),
    __metadata("design:type", ElementRef)
], SplitButtonComponent.prototype, "button", void 0);
__decorate([
    ViewChild('arrowButton', { static: true, read: ElementRef }),
    __metadata("design:type", ElementRef)
], SplitButtonComponent.prototype, "arrowButton", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], SplitButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], SplitButtonComponent.prototype, "containerRef", void 0);
__decorate([
    HostBinding('class.k-state-focused'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], SplitButtonComponent.prototype, "isFocused", null);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-split-button'),
    HostBinding('class.k-button-group'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitButtonComponent.prototype, "widgetClasses", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], SplitButtonComponent.prototype, "dir", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SplitButtonComponent.prototype, "keydown", null);
__decorate([
    HostListener('keypress', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SplitButtonComponent.prototype, "keypress", null);
__decorate([
    HostListener('keyup', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SplitButtonComponent.prototype, "keyup", null);
SplitButtonComponent = __decorate([
    Component({
        exportAs: 'kendoSplitButton',
        providers: [
            FocusService,
            NavigationService,
            NAVIGATION_SETTINGS_PROVIDER,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.splitbutton'
            }
        ],
        selector: 'kendo-splitbutton',
        template: `
        <button
            kendoButton
            #button
            role="listbox"
            type="button"
            [look]="look"
            [tabindex]="componentTabIndex"
            [disabled]="disabled"
            [icon]="icon"
            [class.k-state-active]="active"
            [iconClass]="iconClass"
            [imageUrl]="imageUrl"
            [ngClass]="buttonClass"
            (focus)="onButtonFocus()"
            (click)="onButtonClick()"
            (blur)="onButtonBlur()"
            (mousedown)="toggleButtonActiveState(true)"
            (mouseup)="toggleButtonActiveState(false)"
            [attr.aria-disabled]="disabled"
            [attr.aria-expanded]="openState"
            [attr.aria-haspopup]="true"
            [attr.aria-owns]="listId"
            [attr.aria-label]="ariaLabel"
        >
            {{ text }}<ng-content></ng-content>
        </button>
        <button
            kendoButton
            #arrowButton
            type="button"
            [class.k-state-active]="activeArrow"
            [disabled]="disabled"
            [icon]="arrowButtonIcon"
            [look]="look"
            [tabindex]="-1"
            [ngClass]="arrowButtonClass"
            (click)="onArrowButtonClick()"
            (mousedown)="toggleArrowButtonActiveState(true)"
            (mouseup)="toggleArrowButtonActiveState(false)"
        ></button>
        <ng-template #popupTemplate>
            <kendo-button-list
                [id]="listId"
                [data]="data"
                [textField]="textField"
                [itemTemplate]="itemTemplate"
                (onItemClick)="onItemClick($event)"
                (keydown)="keyDownHandler($event)"
                (keypress)="keyPressHandler($event)"
                (keyup)="keyUpHandler($event)"
                [attr.dir]="dir"
            >
            </kendo-button-list>
        </ng-template>
        <ng-container #container></ng-container>
    `
    }),
    __metadata("design:paramtypes", [FocusService,
        NavigationService,
        ElementRef,
        NgZone,
        PopupService,
        ElementRef,
        LocalizationService,
        ChangeDetectorRef])
], SplitButtonComponent);

/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `SplitButtonComponent`&mdash;The SplitButtonComponent component class.
 */
let SplitButtonModule = class SplitButtonModule {
};
SplitButtonModule = __decorate([
    NgModule({
        declarations: [SplitButtonComponent],
        exports: [SplitButtonComponent, ListModule],
        imports: [CommonModule, PopupModule, ButtonModule, ListModule]
    })
], SplitButtonModule);

const NAVIGATION_SETTINGS$1 = {
    useLeftRightArrows: true
};
const ɵ0$1 = NAVIGATION_SETTINGS$1;
const NAVIGATION_SETTINGS_PROVIDER$1 = {
    provide: NAVIGATION_CONFIG,
    useValue: ɵ0$1
};
/**
 * Represents the Kendo UI DropDownButton component for Angular.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-dropdownbutton [data]="data">
 *    User Settings
 *  </kendo-dropdownbutton>
 * `
 * })
 * class AppComponent {
 *   public data: Array<any> = [{
 *       text: 'My Profile'
 *   }, {
 *       text: 'Friend Requests'
 *   }, {
 *       text: 'Account Settings'
 *   }, {
 *       text: 'Support'
 *   }, {
 *       text: 'Log Out'
 *   }];
 * }
 * ```
 */
let DropDownButtonComponent = class DropDownButtonComponent extends ListButton {
    constructor(focusService, navigationService, wrapperRef, zone, popupService, elRef, localization, cdr) {
        super(focusService, navigationService, wrapperRef, zone, localization, cdr);
        this.popupService = popupService;
        this.elRef = elRef;
        /**
         * Defines the name of an existing icon in a Kendo UI theme.
         */
        this.icon = '';
        /**
         * Defines the list of CSS classes which are used for styling the Button with custom icons.
         */
        this.iconClass = '';
        /**
         * Defines a URL for styling the button with a custom image.
         */
        this.imageUrl = '';
        /**
         * Adds visual weight to the default button and makes it primary.
         */
        this.primary = false;
        /**
         * Changes the visual appearance by using alternative styling options.
         *
         * The available values are:
         * * `flat`
         * * `outline`
         */
        this.look = 'default';
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabIndex = 0;
        /**
         * Fires each time the user clicks on a drop-down list item. The event data contains the data item bound to the clicked list item.
         */
        this.itemClick = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed.
         */
        this.open = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open.
         */
        this.close = new EventEmitter();
        /**
         * Fires each time the DropDownButton gets focused.
         */
        this.onFocus = new EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the DropDownButton gets blurred.
         */
        this.onBlur = new EventEmitter(); //tslint:disable-line:no-output-rename
        this.listId = guid();
        this._itemClick = this.itemClick;
        this._blur = this.onBlur;
    }
    /**
     * Configures the popup of the DropDownButton.
     *
     * The available options are:
     * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
     * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
     * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
     * - `align: "left" | "center" | "right"`&mdash;Specifies the alignment of the popup.
     */
    set popupSettings(settings) {
        this._popupSettings = Object.assign({ animate: true, popupClass: '' }, settings);
    }
    get popupSettings() {
        return this._popupSettings;
    }
    /**
     * Sets the disabled state of the DropDownButton.
     */
    set disabled(value) {
        if (value && this.openState) {
            this.openState = false;
        }
        this._disabled = value;
    }
    get disabled() {
        return this._disabled;
    }
    /**
     * Sets or gets the data of the DropDownButton.
     *
     * > The data has to be provided in an array-like list.
     */
    set data(data) {
        this._data = data || [];
    }
    get data() {
        return this._data;
    }
    /**
     * @hidden
     */
    set openState(open) {
        if (this.disabled) {
            return;
        }
        const eventArgs = new PreventableEvent();
        if (open) {
            this.open.emit(eventArgs);
        }
        else {
            this.close.emit(eventArgs);
        }
        if (eventArgs.isDefaultPrevented()) {
            return;
        }
        this._toggle(open);
    }
    /**
     * @hidden
     */
    get openState() {
        return this._open;
    }
    /**
     * @hidden
     */
    get componentTabIndex() {
        return this.disabled ? (-1) : this.tabIndex;
    }
    get appendTo() {
        const { appendTo } = this.popupSettings;
        if (!appendTo || appendTo === 'root') {
            return undefined;
        }
        return appendTo === 'component' ? this.container : appendTo;
    }
    get focused() {
        return this._isFocused && !this._disabled;
    }
    get widgetClasses() {
        return true;
    }
    get dir() {
        return this.direction;
    }
    /**
     * @hidden
     */
    get active() {
        return this._active;
    }
    /**
     * @hidden
     */
    keydown(event) {
        this.keyDownHandler(event);
        if (event.keyCode === Keys.Space) {
            this._active = true;
        }
    }
    /**
     * @hidden
     */
    keypress(event) {
        this.keyPressHandler(event);
    }
    /**
     * @hidden
     */
    keyup(event) {
        this.keyUpHandler(event);
        this._active = false;
    }
    /**
     * @hidden
     */
    mousedown(event) {
        if (this._disabled) {
            event.preventDefault();
        }
        this._active = true;
    }
    /**
     * @hidden
     */
    mouseup(event) {
        if (this._disabled) {
            event.preventDefault();
        }
        this._active = false;
    }
    /**
     * @hidden
     */
    openPopup() {
        this.togglePopupVisibility();
    }
    /**
     * @hidden
     */
    onButtonBlur() {
        if (!this.isOpen) {
            this.blurWrapper();
        }
    }
    /**
     * @hidden
     */
    get anchorAlign() {
        let align = { horizontal: this.popupSettings.align || 'left', vertical: 'bottom' };
        if (this.direction === 'rtl' && !isPresent(this.popupSettings.align)) {
            align.horizontal = 'right';
        }
        return align;
    }
    /**
     * @hidden
     */
    get popupAlign() {
        let align = { horizontal: this.popupSettings.align || 'left', vertical: 'top' };
        if (this.direction === 'rtl' && !isPresent(this.popupSettings.align)) {
            align.horizontal = 'right';
        }
        return align;
    }
    /**
     * Focuses the DropDownButton component.
     */
    focus() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    }
    /**
     * Blurs the DropDownButton component.
     */
    blur() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.blur();
            this.blurWrapper();
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyPopup();
    }
    /**
     * Toggles the visibility of the popup.
     * If the `toggle` method is used to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    toggle(open) {
        if (this.disabled) {
            return;
        }
        tick(() => (this._toggle((open === undefined) ? !this._open : open)));
    }
    /**
     * Returns the current open state of the popup.
     */
    get isOpen() {
        return this.openState;
    }
    /**
     * @hidden
     */
    handleFocus() {
        if (!this._disabled && !this._isFocused) {
            this._isFocused = true;
            this.onFocus.emit();
        }
    }
    /**
     * @hidden
     */
    wrapperContains(element) {
        return this.wrapper === element
            || this.wrapper.contains(element)
            || (this.popupRef && this.popupRef.popupElement.contains(element));
    }
    subscribeNavigationEvents() {
        this.navigationSubscription = this.navigationService.navigate
            .subscribe(this.onArrowKeyNavigate.bind(this));
        this.enterUpSubscription = this.navigationService.enterup.subscribe(this.onNavigationEnterUp.bind(this));
        this.openSubscription = this.navigationService.open.subscribe(this.onNavigationOpen.bind(this));
        this.closeSubscription = merge(this.navigationService.close, this.navigationService.esc).subscribe(this.onNavigationClose.bind(this));
    }
    onNavigationEnterUp() {
        if (!this._disabled && !this.openState) {
            this._active = false;
        }
        if (this.openState) {
            let focused = this.focusService.focused;
            if (isPresent(focused) && focused !== -1) {
                this.emitItemClickHandler(focused);
            }
        }
        this.togglePopupVisibility();
        if (!this.openState && isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    }
    onNavigationOpen() {
        if (!this._disabled && !this.openState) {
            this.togglePopupVisibility();
        }
    }
    onNavigationClose() {
        if (this.openState) {
            this.togglePopupVisibility();
            if (isDocumentAvailable()) {
                this.button.nativeElement.focus();
            }
        }
    }
    onArrowKeyNavigate(index) {
        this.focusService.focus(index);
    }
    _toggle(open) {
        if (this._open === open) {
            return;
        }
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    }
    createPopup() {
        this.popupRef = this.popupService.open({
            anchor: this.elRef,
            anchorAlign: this.anchorAlign,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupAlign: this.popupAlign,
            popupClass: this.popupClasses
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => this.openState = false);
        this.popupRef.popupOpen.subscribe(this.focusFirstItem.bind(this));
    }
    destroyPopup() {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownButtonComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownButtonComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownButtonComponent.prototype, "imageUrl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownButtonComponent.prototype, "popupSettings", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownButtonComponent.prototype, "textField", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], DropDownButtonComponent.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DropDownButtonComponent.prototype, "data", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DropDownButtonComponent.prototype, "primary", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DropDownButtonComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DropDownButtonComponent.prototype, "buttonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DropDownButtonComponent.prototype, "tabIndex", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownButtonComponent.prototype, "itemClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownButtonComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DropDownButtonComponent.prototype, "close", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], DropDownButtonComponent.prototype, "onFocus", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], DropDownButtonComponent.prototype, "onBlur", void 0);
__decorate([
    HostBinding('class.k-state-focused'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownButtonComponent.prototype, "focused", null);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-dropdown-button'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DropDownButtonComponent.prototype, "widgetClasses", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DropDownButtonComponent.prototype, "dir", null);
__decorate([
    ContentChild(ButtonItemTemplateDirective, { static: false }),
    __metadata("design:type", ButtonItemTemplateDirective)
], DropDownButtonComponent.prototype, "itemTemplate", void 0);
__decorate([
    ViewChild('button', { static: true }),
    __metadata("design:type", ElementRef)
], DropDownButtonComponent.prototype, "button", void 0);
__decorate([
    ViewChild('buttonList', { static: false }),
    __metadata("design:type", ListComponent)
], DropDownButtonComponent.prototype, "buttonList", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], DropDownButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ViewChild('container', { read: ViewContainerRef, static: true }),
    __metadata("design:type", ViewContainerRef)
], DropDownButtonComponent.prototype, "container", void 0);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownButtonComponent.prototype, "keydown", null);
__decorate([
    HostListener('keypress', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownButtonComponent.prototype, "keypress", null);
__decorate([
    HostListener('keyup', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownButtonComponent.prototype, "keyup", null);
__decorate([
    HostListener('mousedown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownButtonComponent.prototype, "mousedown", null);
__decorate([
    HostListener('mouseup', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], DropDownButtonComponent.prototype, "mouseup", null);
DropDownButtonComponent = __decorate([
    Component({
        exportAs: 'kendoDropDownButton',
        providers: [
            FocusService,
            NavigationService,
            NAVIGATION_SETTINGS_PROVIDER$1,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.dropdownbutton'
            }
        ],
        selector: 'kendo-dropdownbutton',
        template: `
        <button kendoButton #button
            role="menu"
            type="button"
            [tabindex]="componentTabIndex"
            [class.k-state-active]="active"
            [disabled]="disabled"
            [icon]="icon"
            [iconClass]="iconClass"
            [imageUrl]="imageUrl"
            [ngClass]="buttonClass"
            (click)="openPopup()"
            (focus)="handleFocus()"
            (blur)="onButtonBlur()"
            [attr.aria-disabled]="disabled"
            [attr.aria-expanded]="openState"
            [attr.aria-haspopup]="true"
            [attr.aria-owns]="listId"
            [look]="look"
            [primary]="primary"
            >
            <ng-content></ng-content>
        </button>
        <ng-template #popupTemplate>
            <kendo-button-list
                #buttonList
                [id]="listId"
                [data]="data"
                [textField]="textField"
                [itemTemplate]="itemTemplate"
                (onItemClick)="onItemClick($event)"
                (keydown)="keyDownHandler($event)"
                (keypress)="keyPressHandler($event)"
                (keyup)="keyUpHandler($event)"
                [attr.dir]="dir"
            >
            </kendo-button-list>
        </ng-template>
        <ng-container #container></ng-container>
    `
    }),
    __metadata("design:paramtypes", [FocusService,
        NavigationService,
        ElementRef,
        NgZone,
        PopupService,
        ElementRef,
        LocalizationService,
        ChangeDetectorRef])
], DropDownButtonComponent);

/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `DropDownButtonComponent`&mdash;The DropDownButtonComponent component class.
 */
let DropDownButtonModule = class DropDownButtonModule {
};
DropDownButtonModule = __decorate([
    NgModule({
        declarations: [DropDownButtonComponent],
        exports: [DropDownButtonComponent, ListModule],
        imports: [CommonModule, PopupModule, ListModule, ButtonModule]
    })
], DropDownButtonModule);

/**
 * Displays a Chip that represents an input, attribute or an action.
 */
let ChipComponent = class ChipComponent {
    constructor(element, renderer, ngZone, localizationService) {
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.localizationService = localizationService;
        /**
         * Changes the visual appearance by using alternative styling options.
         *
         * The available values are:
         * * `filled`
         * * `outlined`
         */
        this.look = 'filled';
        /**
         * Specifies the selected state of the Chip.
         */
        this.selected = false;
        /**
         * Specifies if the Chip will be removable or not.
         * If the property is set to `true`, the Chip renders a remove icon.
         */
        this.removable = false;
        /**
         * Specifies the Chip type.
         *
         * The possible values are:
         * - `none` (default)
         * - `success`
         * - `error`
         * - `warning`
         * - `info`
         */
        this.type = 'none';
        /**
         * If set to `true`, the Chip will be disabled.
         */
        this.disabled = false;
        /**
         * Fires each time the user clicks the remove icon of the Chip.
         */
        this.remove = new EventEmitter();
        this.tabIndex = 0;
        this.hostClass = true;
        this.focused = false;
        validatePackage(packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    get outlineClass() {
        return this.look === 'outlined';
    }
    get filledClass() {
        return this.look === 'filled';
    }
    get hasIconClass() {
        return this.icon || this.iconClass ? true : false;
    }
    get disabledClass() {
        return this.disabled;
    }
    get selectedClass() {
        return this.selected;
    }
    get focusedClass() {
        return this.focused;
    }
    ngOnInit() {
        this.dynamicRTLSubscription = this.localizationService.changes
            .subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
        if (this.detachDomEvents) {
            this.detachDomEvents();
        }
    }
    ngAfterViewInit() {
        const chip = this.element.nativeElement;
        const typeClass = this.typeClass();
        if (this.type !== 'none') {
            this.renderer.addClass(chip, typeClass);
        }
        this.attachElementEventHandlers(chip);
    }
    /**
     * @hidden
     */
    get iconClasses() {
        if (this.icon) {
            return `k-icon k-i-${this.icon}`;
        }
        if (this.iconClass) {
            return `${this.iconClass}`;
        }
    }
    /**
     * @hidden
     */
    get selectedIconClasses() {
        if (this.selectedIcon) {
            return `${this.selectedIcon}`;
        }
        return `k-icon k-i-check`;
    }
    /**
     * @hidden
     */
    get removeIconClasses() {
        if (this.removeIcon) {
            return `${this.removeIcon}`;
        }
        return `k-icon k-i-close-circle`;
    }
    /**
     * @hidden
     */
    typeClass() {
        return {
            'none': '',
            'success': 'k-chip-success',
            'warning': 'k-chip-warning',
            'error': 'k-chip-error',
            'info': 'k-chip-info'
        }[this.type];
    }
    /**
     * Focuses the Chip component.
     */
    focus() {
        if (isDocumentAvailable()) {
            this.element.nativeElement.focus();
        }
    }
    /**
     * Blurs the Chip component.
     */
    blur() {
        if (isDocumentAvailable()) {
            this.element.nativeElement.blur();
        }
    }
    /**
     * @hidden
     */
    onRemoveClick(e) {
        if (this.removable) {
            this.remove.emit({ sender: this, originalEvent: e });
        }
    }
    attachElementEventHandlers(chip) {
        this.ngZone.runOutsideAngular(() => {
            const focusListener = this.renderer.listen(chip, 'focus', () => {
                this.renderer.addClass(chip, 'k-chip-focused');
            });
            const blurListener = this.renderer.listen(chip, 'blur', () => {
                this.renderer.removeClass(chip, 'k-chip-focused');
            });
            this.detachDomEvents = () => {
                focusListener();
                blurListener();
            };
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "look", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "selected", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "selectedIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "removable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "removeIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "disabled", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ChipComponent.prototype, "remove", void 0);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number)
], ChipComponent.prototype, "tabIndex", void 0);
__decorate([
    HostBinding('class.k-chip'),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-chip-outline'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "outlineClass", null);
__decorate([
    HostBinding('class.k-chip-filled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "filledClass", null);
__decorate([
    HostBinding('class.k-chip-has-icon'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "hasIconClass", null);
__decorate([
    HostBinding('class.k-chip-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('class.k-chip-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "selectedClass", null);
__decorate([
    HostBinding('class.k-chip-focused'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipComponent.prototype, "focusedClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], ChipComponent.prototype, "direction", void 0);
ChipComponent = __decorate([
    Component({
        selector: 'kendo-chip',
        template: `
        <span class="k-selected-icon-wrapper">
            <span *ngIf="selected"
                class="k-selected-icon"
                [ngClass]="selectedIconClasses"
            >
            </span>
        </span>

        <span *ngIf="icon || iconClass"
            class="k-chip-icon"
            [ngClass]="iconClasses"
        >
        </span>

        <span class="k-chip-content">
            <span class="k-chip-label" *ngIf="label">
                {{ label }}
            </span>
            <ng-content *ngIf="!label"></ng-content>
        </span>

        <span *ngIf="removable"
            class="k-remove-icon"
            (click)="onRemoveClick($event)"
        >
            <span [ngClass]="removeIconClasses"></span>
        </span>
    `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.chip'
            }
        ]
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        NgZone,
        LocalizationService])
], ChipComponent);

let ChipListComponent = class ChipListComponent {
    constructor(localizationService) {
        this.localizationService = localizationService;
        this.hostClass = true;
        /**
         * Sets the selection mode of the ChipList.
         *
         * The available values are:
         * * `none` (default)
         * * `single`
         * * `multiple`
         */
        this.selection = 'none';
        /**
         * Fires each time when the ChipList selection is changed.
         */
        this.selectedChange = new EventEmitter();
        /**
         * Fires each time the user clicks on the remove icon of the Chip.
         */
        this.remove = new EventEmitter();
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    get single() {
        return this.selection === 'single';
    }
    get multiple() {
        return this.selection === 'multiple';
    }
    /**
     * @hidden
     */
    onClick($event) {
        const target = $event.target;
        const isRemoveClicked = closest(target, '.k-remove-icon');
        const clickedChip = closest(target, '.k-chip');
        const chip = this.chips.find((chip) => clickedChip === chip.element.nativeElement);
        if (isRemoveClicked && clickedChip) {
            const removeEventArgs = { sender: this, originalEvent: $event, removedChip: chip };
            this.remove.emit(removeEventArgs);
        }
        if (this.selection !== 'none' && clickedChip && !isRemoveClicked) {
            this.setSelection(chip);
        }
    }
    ngOnInit() {
        this.dynamicRTLSubscription = this.localizationService.changes
            .subscribe(({ rtl }) => this.direction = rtl ? 'rtl' : 'ltr');
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    selectedChips() {
        return this.chips.reduce((acc, cur, idx) => { return cur.selected ? acc.concat(idx) : acc; }, []);
    }
    /**
     * Updates the selection on click of a Chip. Emits events.
     */
    setSelection(chip) {
        if (this.selection === 'single') {
            this.clearSelection(chip);
        }
        chip.selected = !chip.selected;
        this.selectedChange.emit(this.selectedChips());
    }
    clearSelection(chip) {
        this.chips.forEach((c) => {
            if (chip !== c) {
                c.selected = false;
            }
        });
    }
};
__decorate([
    HostBinding('class.k-chip-list'),
    __metadata("design:type", Boolean)
], ChipListComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], ChipListComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipListComponent.prototype, "selection", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ChipListComponent.prototype, "selectedChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ChipListComponent.prototype, "remove", void 0);
__decorate([
    ContentChildren(ChipComponent),
    __metadata("design:type", QueryList)
], ChipListComponent.prototype, "chips", void 0);
__decorate([
    HostBinding('class.k-selection-single'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipListComponent.prototype, "single", null);
__decorate([
    HostBinding('class.k-selection-multiple'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ChipListComponent.prototype, "multiple", null);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ChipListComponent.prototype, "onClick", null);
ChipListComponent = __decorate([
    Component({
        selector: 'kendo-chip-list',
        template: `
        <ng-content></ng-content>
    `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.chip-list'
            }
        ]
    }),
    __metadata("design:paramtypes", [LocalizationService])
], ChipListComponent);

const exportedModules = [
    ChipComponent,
    ChipListComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Chip and ChipList components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Chip module
 * import { ChipModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ChipModule], // import Chip module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
let ChipModule = class ChipModule {
};
ChipModule = __decorate([
    NgModule({
        declarations: [declarations],
        exports: [exportedModules],
        imports: [CommonModule]
    })
], ChipModule);

/**
 * @hidden
 */
function getAnchorAlign(fabAlign, rtl) {
    let align = { horizontal: (rtl ? 'right' : 'left'), vertical: 'bottom' };
    if (fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
    }
    if (fabAlign.vertical === 'bottom') {
        align.vertical = 'top';
    }
    if (fabAlign.vertical === 'bottom' && fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
        align.vertical = 'top';
    }
    return align;
}
/**
 * @hidden
 */
function getPopupAlign(fabAlign, rtl) {
    let align = { horizontal: (rtl ? 'right' : 'left'), vertical: 'top' };
    if (fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
    }
    if (fabAlign.vertical === 'bottom') {
        align.vertical = 'bottom';
    }
    if (fabAlign.vertical === 'bottom' && fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
        align.vertical = 'bottom';
    }
    return align;
}

/**
 * @hidden
 */
function openAnimation(animationSettings) {
    const isBottom = animationSettings.align.vertical === 'bottom';
    const duration = animationSettings.duration;
    const gap = animationSettings.gap;
    return sequence([
        query(`.k-fab-item`, [
            style({ opacity: 0, transform: `translateY(${isBottom ? '8px' : '-8px'})` }),
            stagger(gap, [animate(`${duration}ms ease-in`, style({ opacity: '*', transform: 'translateY(0)' }))])
        ], { optional: true })
    ]);
}
/**
 * @hidden
 */
function closeAnimation(animationSettings) {
    const isBottom = animationSettings.align.vertical === 'bottom';
    const duration = animationSettings.duration;
    const gap = animationSettings.gap;
    return sequence([
        query(`.k-fab-item`, [
            style({ opacity: '*', transform: 'translateY(0)' }),
            stagger(-gap, [animate(`${duration}ms ease-in`, style({ opacity: 0, transform: `translateY(${isBottom ? '8px' : '-8px'})` }))])
        ], { optional: true })
    ]);
}

/**
 * Represents a template that defines the content of the whole dial item.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDialItemTemplate` directive inside the `<kendo-floatingactionbutton>` tag
 * ([see example]({% slug templates_floatingactionbutton %}#toc-dial-item-template)).
 */
let DialItemTemplateDirective = class DialItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DialItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDialItemTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DialItemTemplateDirective);

/**
 * Represents a template that defines the content of the FloatingActionButton.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoFloatingActionButtonTemplate` directive inside the `<kendo-floatingactionbutton>` tag
 * ([see example]({% slug templates_floatingactionbutton %}#toc-floatingactionbutton-template)).
 */
let FloatingActionButtonTemplateDirective = class FloatingActionButtonTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
FloatingActionButtonTemplateDirective = __decorate([
    Directive({
        selector: '[kendoFloatingActionButtonTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], FloatingActionButtonTemplateDirective);

const NAVIGATION_SETTINGS$2 = {
    useLeftRightArrows: false
};
const ɵ0$2 = NAVIGATION_SETTINGS$2;
const NAVIGATION_SETTINGS_PROVIDER$2 = {
    provide: NAVIGATION_CONFIG,
    useValue: ɵ0$2
};
const SIZE_CLASSES = {
    small: 'k-fab-sm',
    medium: 'k-fab-md',
    large: 'k-fab-lg'
};
const SHAPE_CLASSES = {
    pill: 'k-fab-pill',
    circle: 'k-fab-circle',
    rectangle: 'k-fab-rectangle',
    square: 'k-fab-square',
    rounded: 'k-fab-rounded'
};
const DEFAULT_DURATION = 180;
const DEFAULT_ITEM_GAP = 90;
const DEFAULT_OFFSET = '16px';
/**
 *
 * Represents the [Kendo UI FloatingActionButton component for Angular]({% slug overview_floatingactionbutton %}).
 * Used to specify the primary or the most common action in an application.
 *
 */
let FloatingActionButtonComponent = class FloatingActionButtonComponent {
    constructor(renderer, element, focusService, navigationService, ngZone, popupService, builder, localizationService) {
        this.renderer = renderer;
        this.element = element;
        this.focusService = focusService;
        this.navigationService = navigationService;
        this.ngZone = ngZone;
        this.popupService = popupService;
        this.builder = builder;
        this.localizationService = localizationService;
        /**
         * Specifies the positionMode of the FloatingActionButton
         * ([see example]({% slug positioning_floatingactionbutton %}#toc-positionMode)).
         *
         * * The possible values are:
         * * `absolute`&mdash;Positions the FloatingActionButton absolutely to its first positioned parent element.
         * * `fixed` (Default)&mdash;Positions the FloatingActionButton relative to the viewport. It always stays in the same place even if the page is scrolled.
         */
        this.positionMode = 'fixed';
        /**
         * Specifies the animation settings of the FloatingActionButton dial items.
         * ([see example]({% slug dialitems_floatingactionbutton %}#toc-animation)).
         *
         * The possible values are:
         * * Boolean
         *    * (Default) `true`&mdash;Applies the default [`DialItemAnimation`]({% slug api_buttons_dialitemanimation %}) settings.
         *    * `false`
         * * `DialItemAnimation`
         *    * `duration`&mdash;Specifies the animation duration in milliseconds for each dial item. Defaults to `180ms`.
         *    * `gap`&mdash;Specifies the animation duration gap in milliseconds after each dial item is animated. Defaults to `90ms`.
         */
        this.dialItemAnimation = true;
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the FloatingActionButton.
         */
        this.tabIndex = 0;
        /**
         * Specifies the collection of the dial items that will be rendered in the FloatingActionButton popup.
         */
        this.dialItems = [];
        /**
         * Fires each time the FloatingActionButton gets blurred.
         */
        this.onBlur = new EventEmitter();
        /**
         * Fires each time the FloatingActionButton gets focused.
         */
        this.onFocus = new EventEmitter();
        /**
         * Fires each time a dial item is clicked.
         */
        this.dialItemClick = new EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed
         * ([more information and example]({% slug overview_floatingactionbutton %}#toc-events)).
         */
        this.open = new EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open
         * ([more information and example]({% slug overview_floatingactionbutton %}#toc-events)).
         */
        this.close = new EventEmitter();
        /**
         * @hidden
         */
        this.id = `k-${guid()}`;
        this._themeColor = 'primary';
        this._size = 'medium';
        this._shape = 'pill';
        this._disabled = false;
        this._align = { horizontal: 'end', vertical: 'bottom' };
        this._offset = { x: DEFAULT_OFFSET, y: DEFAULT_OFFSET };
        this.subscriptions = new Subscription();
        this.rtl = false;
        this.animationEnd = new EventEmitter();
        validatePackage(packageMetadata);
        this.subscribeNavigationEvents();
        this.subscriptions.add(this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        }));
    }
    get fixedClass() {
        return this.positionMode === 'fixed';
    }
    get absoluteClass() {
        return this.positionMode === 'absolute';
    }
    /**
     * Specifies the theme color of the FloatingActionButton
     * ([see example]({% slug appearance_floatingactionbutton %}#toc-themeColor)).
     * The theme color will be applied as background color of the component.
     *
     * The possible values are:
     * * `primary` (Default)&mdash;Applies coloring based on the `primary` theme color.
     * * `secondary`&mdash;Applies coloring based on the `secondary` theme color.
     * * `tertiary`&mdash; Applies coloring based on the `tertiary` theme color.
     * * `inherit`&mdash; Applies inherited coloring value.
     * * `info`&mdash;Applies coloring based on the `info` theme color.
     * * `success`&mdash; Applies coloring based on the `success` theme color.
     * * `warning`&mdash; Applies coloring based on the `warning` theme color.
     * * `error`&mdash; Applies coloring based on the `error` theme color.
     * * `dark`&mdash; Applies coloring based on the `dark` theme color.
     * * `light`&mdash; Applies coloring based on the `light` theme color.
     * * `inverse`&mdash; Applies coloring based on the `inverse` theme color.
     *
     */
    set themeColor(themeColor) {
        const button = this.button.nativeElement;
        this.renderer.removeClass(button, `k-fab-${this.themeColor}`);
        this.renderer.addClass(button, `k-fab-${themeColor}`);
        this._themeColor = themeColor;
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the size of the FloatingActionButton
     * ([see example]({% slug appearance_floatingactionbutton %}#toc-size)).
     *
     * The possible values are:
     * * `small`
     * * `medium` (Default)
     * * `large`
     *
     */
    set size(size) {
        const button = this.button.nativeElement;
        this.renderer.removeClass(button, SIZE_CLASSES[this.size]);
        this.renderer.addClass(button, SIZE_CLASSES[size]);
        this._size = size;
    }
    get size() {
        return this._size;
    }
    /**
     * Specifies the shape of the FloatingActionButton
     * ([see example]({% slug appearance_floatingactionbutton %}#toc-shape)).
     *
     * The possible values are:
     * * `pill` (Default)&mdash;Applies border radius equal to half of the height of the FloatingActionButton.
     * * `circle`&mdash;Applies circle shape on the FloatingActionButton.
     * * `rectangle`&mdash;Applies rectangular shape on the FloatingActionButton.
     * * `rounded`&mdash;Applies rounded shape on the FloatingActionButton.
     * * `square`&mdash;Applies square shape on the FloatingActionButton.
     *
     */
    set shape(shape) {
        const button = this.button.nativeElement;
        this.renderer.removeClass(button, SHAPE_CLASSES[this.shape]);
        this.renderer.addClass(button, SHAPE_CLASSES[shape]);
        this._shape = shape;
    }
    get shape() {
        return this._shape;
    }
    /**
     * Specifies whether the FloatingActionButton is disabled.
     */
    set disabled(disabled) {
        this._disabled = disabled;
    }
    get disabled() {
        return this._disabled;
    }
    /**
     * Specifies the horizontal and vertical alignment of the FloatingActionButton
     * ([see example]({% slug positioning_floatingactionbutton %}#toc-align)).
     *
     * The possible values are:
     * * `{ horizontal: 'start'|'center'|'end', vertical: 'top'|'middle'|'bottom' }`
     *
     * The default value is:
     * * `{ horizontal: 'end', vertical: 'bottom' }`
     *
     */
    set align(align) {
        this._align = Object.assign(this._align, align);
    }
    get align() {
        return this._align;
    }
    /**
     * Specifies the horizontal and vertical offset position of the FloatingActionButton
     * ([see example]({% slug positioning_floatingactionbutton %}#toc-offset)).
     *
     * * The default value is:
     * * `{ x: '16px', y: '16px' }`
     */
    set offset(offset) {
        this._offset = Object.assign(this._offset, offset);
        this.offsetStyles();
    }
    get offset() {
        return this._offset;
    }
    /**
     * @hidden
     */
    get componentTabIndex() {
        return this.disabled ? (-1) : this.tabIndex;
    }
    ngAfterViewInit() {
        this.applyClasses();
        this.offsetStyles();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    /**
     * Indicates whether the FloatingActionButton is currently open.
     */
    get isOpen() { return isPresent(this.popupRef); }
    /**
     * Focuses the FloatingActionButton.
     */
    focus() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    }
    /**
     * Blurs the FloatingActionButton.
     */
    blur() {
        if (isDocumentAvailable()) {
            this.button.nativeElement.blur();
        }
    }
    /**
     * Toggles the visibility of the FloatingActionButton dial items popup.
     *
     * If you use the `toggleDial` method to open or close the dial items,
     * the `open` and `close` events do not fire ([more information and examples]({% slug openstate_floatingactionbutton %}#toc-events)).
     *
     * @param open - The state of dial items popup.
     */
    toggleDial(open) {
        if (this.disabled || !this.hasDialItems) {
            return;
        }
        const shouldOpen = isPresent(open) ? open : !this.isOpen;
        if (this.disabled || shouldOpen === this.isOpen) {
            return;
        }
        shouldOpen ? setTimeout(() => this.openDial()) : this.closeDial();
    }
    /**
     * @hidden
     */
    get role() {
        return (this.dialItems && this.dialItems.length) ? 'menubutton' : 'button';
    }
    /**
     * @hidden
     */
    get ariaExpanded() {
        return isPresent(this.isOpen);
    }
    /**
     * @hidden
     */
    get ariaHasPopup() {
        return isPresent(this.isOpen);
    }
    /**
     * @hidden
     */
    get iconClasses() {
        if (this.iconClass) {
            return `${this.iconClass}`;
        }
        return `k-fab-icon k-icon k-i-${this.icon}`;
    }
    /**
     * @hidden
     */
    clickHandler() {
        if (this.disabled || !this.hasDialItems) {
            return;
        }
        this.ngZone.run(() => {
            const shouldOpen = !this.isOpen;
            this.toggleDialWithEvents(shouldOpen);
        });
    }
    /**
     * @hidden
     */
    keyDownHandler(event) {
        this.keyHandler(event);
    }
    /**
     * @hidden
     */
    keyHandler(event, keyEvent) {
        if (this.disabled) {
            return;
        }
        const focused = this.focusService.focused || 0;
        const keyCode = event.keyCode;
        const action = this.navigationService.process({
            altKey: event.altKey,
            current: focused,
            keyCode,
            keyEvent: keyEvent,
            max: this.dialItems ? this.dialItems.length - 1 : 0,
            min: 0,
            flipNavigation: this.align.vertical === 'bottom'
        });
        if (action !== NavigationAction.Undefined &&
            action !== NavigationAction.Tab) {
            event.preventDefault();
        }
    }
    /**
     * @hidden
     */
    onItemClick(event) {
        const item = closest(event.target, '.k-fab-item');
        if (!item) {
            return;
        }
        const index = item.getAttribute('data-fab-item-index');
        this.emitItemClick(index);
    }
    /**
     * @hidden
     */
    focusHandler() {
        if (!this.disabled) {
            this.onFocus.emit();
        }
    }
    /**
     * @hidden
     */
    blurHandler() {
        this.onBlur.emit();
        this.toggleDialWithEvents(false);
    }
    onEnterPressed() {
        const index = this.focusService.focused;
        this.emitItemClick(index);
    }
    emitItemClick(index) {
        const item = this.dialItems[index];
        if (item && !item.disabled) {
            const clickEventArgs = { item: item, index: index };
            this.dialItemClick.emit(clickEventArgs);
            this.toggleDialWithEvents(false);
        }
    }
    subscribeNavigationEvents() {
        this.subscriptions.add(this.navigationService.navigate.subscribe(this.onArrowKeyNavigate.bind(this)));
        this.subscriptions.add(this.navigationService.enter.subscribe(this.onNavigationEnterPress.bind(this)));
        this.subscriptions.add(merge(this.navigationService.close, this.navigationService.esc).subscribe(this.onNavigationClose.bind(this)));
    }
    onArrowKeyNavigate(index) {
        this.focusService.focus(index);
    }
    onNavigationEnterPress() {
        this.ngZone.run(() => {
            if (this.isOpen) {
                let focusedIndex = this.focusService.focused;
                const focusedItem = this.dialItems[focusedIndex];
                if (focusedItem && focusedItem.disabled) {
                    return;
                }
                if (isPresent(focusedIndex) && focusedIndex !== -1) {
                    this.onEnterPressed();
                    return;
                }
            }
            if (!this.isOpen && isDocumentAvailable()) {
                this.toggleDialWithEvents(true);
                this.button.nativeElement.focus();
            }
        });
    }
    onNavigationClose() {
        if (this.isOpen) {
            this.ngZone.run(() => {
                this.toggleDialWithEvents(false);
                if (isDocumentAvailable()) {
                    this.button.nativeElement.focus();
                }
            });
        }
    }
    alignClass() {
        return `k-pos-${this.align.vertical}-${this.align.horizontal}`;
    }
    applyClasses() {
        const hostElement = this.element.nativeElement;
        const button = this.button.nativeElement;
        this.renderer.addClass(button, SHAPE_CLASSES[this.shape]);
        this.renderer.addClass(button, `k-fab-${this.themeColor}`);
        this.renderer.addClass(button, SIZE_CLASSES[this.size]);
        this.renderer.addClass(hostElement, this.alignClass());
    }
    toggleDialWithEvents(open) {
        if (open === this.isOpen) {
            return;
        }
        const event = new PreventableEvent();
        open ? this.open.emit(event) : this.close.emit(event);
        if (event.isDefaultPrevented()) {
            return;
        }
        open ? this.openDial() : this.closeDial();
    }
    openPopup() {
        if (this.isOpen) {
            return;
        }
        const isIconFab = this.icon && !this.text;
        const rtl = this.rtl;
        const align = this.align;
        this.popupRef = this.popupService.open({
            anchor: this.element.nativeElement,
            animate: false,
            content: this.popupTemplate,
            anchorAlign: getAnchorAlign(align, rtl),
            popupAlign: getPopupAlign(align, rtl),
            popupClass: 'k-fab-popup k-popup-transparent'
        });
        const popupElement = this.popupRef.popupElement;
        this.renderer.setStyle(popupElement, 'box-shadow', 'none');
        if (isIconFab) {
            this.subscriptions.add(this.popupRef.popupOpen.subscribe(() => this.positionPopup()));
        }
        this.ngZone.runOutsideAngular(() => {
            this.popupMouseDownListener = this.renderer.listen(popupElement, 'mousedown', (event) => {
                event.preventDefault();
            });
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(() => this.toggleDialWithEvents(false));
    }
    closePopup() {
        if (this.isOpen) {
            if (this.popupMouseDownListener) {
                this.popupMouseDownListener();
            }
            this.popupRef.close();
            this.popupRef = null;
        }
    }
    openDial() {
        this.openPopup();
        this.focusService.focus(0);
        if (this.dialItemAnimation && this.isValidAnimation()) {
            this.playAnimation(true);
        }
    }
    closeDial() {
        if (this.dialItemAnimation && this.isValidAnimation()) {
            this.playAnimation(false);
            this.animationEnd.pipe(take(1)).subscribe(() => this.closePopup());
        }
        else {
            this.closePopup();
        }
    }
    isValidAnimation() {
        const animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean') {
            return animation.duration !== 0;
        }
        return true;
    }
    positionPopup() {
        if (this.dialItemTemplate) {
            return;
        }
        const fab = this.element.nativeElement;
        const fabWidth = fab.getBoundingClientRect().width;
        const popupEl = this.popupRef.popupElement;
        const icon = popupEl.querySelector('.k-fab-item-icon');
        if (!icon) {
            return;
        }
        const iconWidth = icon.getBoundingClientRect().width;
        const left = (fabWidth / 2) - (iconWidth / 2);
        const popupLeft = popupEl.getBoundingClientRect().left;
        const isEndAlign = this.align.horizontal === 'end';
        const leftValue = isEndAlign ? (popupLeft - left) : (left + popupLeft);
        const rtlLeftValue = isEndAlign ? (left + popupLeft) : (popupLeft - left);
        popupEl.style.left = this.rtl ? `${rtlLeftValue}px` : `${leftValue}px`;
    }
    offsetStyles() {
        const hostElement = this.element.nativeElement;
        this.renderer.setStyle(hostElement, this.horizontalPosition, this.horizontalOffset);
        this.renderer.setStyle(hostElement, this.verticalPosition, this.verticalOffset);
    }
    get hasDialItems() {
        return isPresent(this.dialItems) && this.dialItems.length !== 0;
    }
    /**
     * Gets the CSS prop name of the selected vertical position (`top`/`bottom`);
     */
    get verticalPosition() {
        return {
            top: 'top',
            middle: 'top',
            bottom: 'bottom'
        }[this.align.vertical];
    }
    /**
     * Gets the offset according to the selected vertical position.
     */
    get verticalOffset() {
        if (this.align.vertical === 'middle') {
            return this.offset.y === DEFAULT_OFFSET ? '50%' : `calc(50% + ${this.offset.y})`;
        }
        return this.offset.y;
    }
    /**
     * Gets the CSS prop name of the selected horizontal position (`left`/`right`);
     */
    get horizontalPosition() {
        const { horizontal } = this.align;
        return {
            end: this.rtl ? 'left' : 'right',
            center: 'left',
            start: this.rtl ? 'right' : 'left'
        }[horizontal];
    }
    /**
     * Gets the offset according to the selected horizontal position.
     */
    get horizontalOffset() {
        if (this.align.horizontal === 'center') {
            return this.offset.x === DEFAULT_OFFSET ? '50%' : `calc(50% + ${this.offset.x})`;
        }
        return this.offset.x;
    }
    playerFor(element, animation) {
        const factory = this.builder.build(animation);
        return factory.create(element);
    }
    playAnimation(open) {
        const durationSettings = this.durationSettings();
        const animationSettings = {
            duration: durationSettings.duration,
            gap: durationSettings.gap,
            align: this.align
        };
        const animation = open ? openAnimation(animationSettings) : closeAnimation(animationSettings);
        let player = this.playerFor(this.popupRef.popupElement, animation);
        player.play();
        player.onDone(() => {
            if (player) {
                this.animationEnd.emit();
                player.destroy();
                player = null;
            }
        });
    }
    durationSettings() {
        return {
            duration: this.animationDuration(),
            gap: this.animationGap()
        };
    }
    animationGap() {
        const animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean' && isPresent(animation.gap)) {
            return animation.gap;
        }
        return DEFAULT_ITEM_GAP;
    }
    animationDuration() {
        const animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean' && isPresent(animation.duration)) {
            return animation.duration;
        }
        return DEFAULT_DURATION;
    }
};
__decorate([
    HostBinding('class.k-pos-fixed'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], FloatingActionButtonComponent.prototype, "fixedClass", null);
__decorate([
    HostBinding('class.k-pos-absolute'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], FloatingActionButtonComponent.prototype, "absoluteClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], FloatingActionButtonComponent.prototype, "direction", void 0);
__decorate([
    ViewChild('button', { static: true }),
    __metadata("design:type", ElementRef)
], FloatingActionButtonComponent.prototype, "button", void 0);
__decorate([
    ViewChild('popupTemplate', { static: true }),
    __metadata("design:type", TemplateRef)
], FloatingActionButtonComponent.prototype, "popupTemplate", void 0);
__decorate([
    ContentChild(DialItemTemplateDirective, { static: true }),
    __metadata("design:type", DialItemTemplateDirective)
], FloatingActionButtonComponent.prototype, "dialItemTemplate", void 0);
__decorate([
    ContentChild(FloatingActionButtonTemplateDirective, { static: true }),
    __metadata("design:type", FloatingActionButtonTemplateDirective)
], FloatingActionButtonComponent.prototype, "fabTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], FloatingActionButtonComponent.prototype, "themeColor", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], FloatingActionButtonComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], FloatingActionButtonComponent.prototype, "shape", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], FloatingActionButtonComponent.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FloatingActionButtonComponent.prototype, "align", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FloatingActionButtonComponent.prototype, "offset", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingActionButtonComponent.prototype, "positionMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingActionButtonComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingActionButtonComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FloatingActionButtonComponent.prototype, "buttonClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FloatingActionButtonComponent.prototype, "dialClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FloatingActionButtonComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FloatingActionButtonComponent.prototype, "dialItemAnimation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FloatingActionButtonComponent.prototype, "tabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], FloatingActionButtonComponent.prototype, "dialItems", void 0);
__decorate([
    Output('blur'),
    __metadata("design:type", EventEmitter)
], FloatingActionButtonComponent.prototype, "onBlur", void 0);
__decorate([
    Output('focus'),
    __metadata("design:type", EventEmitter)
], FloatingActionButtonComponent.prototype, "onFocus", void 0);
__decorate([
    Output('dialItemClick'),
    __metadata("design:type", EventEmitter)
], FloatingActionButtonComponent.prototype, "dialItemClick", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FloatingActionButtonComponent.prototype, "open", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], FloatingActionButtonComponent.prototype, "close", void 0);
FloatingActionButtonComponent = __decorate([
    Component({
        selector: 'kendo-floatingactionbutton',
        providers: [
            FocusService,
            NavigationService,
            NAVIGATION_SETTINGS_PROVIDER$2,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.floatingactionbutton'
            }
        ],
        template: `
        <button
            #button
            [attr.id]="id"
            [attr.role]="role"
            [tabIndex]="componentTabIndex"
            type="button"
            [class.k-fab]="true"
            [class.k-state-disabled]="disabled"
            [ngClass]="buttonClass"
            [disabled]="disabled"
            [attr.aria-disabled]="disabled"
            [attr.aria-expanded]="ariaExpanded"
            [attr.aria-haspopup]="ariaHasPopup"
            (focus)="focusHandler()"
            (blur)="blurHandler()"
            [kendoEventsOutsideAngular]="{
                keydown: keyDownHandler,
                click: clickHandler
            }"
            [scope]="this"
        >
            <ng-template *ngIf="fabTemplate"
                [ngTemplateOutlet]="fabTemplate?.templateRef"
            >
            </ng-template>

            <ng-container *ngIf="!fabTemplate">
                <span *ngIf="icon" [ngClass]="iconClasses"></span>
                <span *ngIf="text" class="k-fab-text">{{ text }}</span>
            </ng-container>
        </button>

        <ng-template #popupTemplate>
            <ul
                kendoDialList
                [ngClass]="dialClass"
                [dialItems]="dialItems"
                [dialItemTemplate]='dialItemTemplate?.templateRef'
                [align]="align"
                [attr.aria-labelledby]="id"
                (click)="onItemClick($event)"
            >
            </ul>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef,
        FocusService,
        NavigationService,
        NgZone,
        PopupService,
        AnimationBuilder,
        LocalizationService])
], FloatingActionButtonComponent);

/**
 * @hidden
 */
let DialListComponent = class DialListComponent {
    constructor(focusService, cdr) {
        this.focusService = focusService;
        this.cdr = cdr;
        this.hostClass = true;
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.focusService.onFocus.subscribe(() => this.cdr.detectChanges()));
    }
    get bottomClass() {
        return this.align.vertical === 'top' || this.align.vertical === 'middle';
    }
    get topClass() {
        return this.align.vertical === 'bottom';
    }
    isFocused(index) {
        return this.focusService.isFocused(index);
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
};
__decorate([
    HostBinding('class.k-fab-items'),
    __metadata("design:type", Boolean)
], DialListComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-fab-items-bottom'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DialListComponent.prototype, "bottomClass", null);
__decorate([
    HostBinding('class.k-fab-items-top'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DialListComponent.prototype, "topClass", null);
__decorate([
    Input(),
    __metadata("design:type", Array)
], DialListComponent.prototype, "dialItems", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], DialListComponent.prototype, "dialItemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialListComponent.prototype, "align", void 0);
DialListComponent = __decorate([
    Component({
        selector: '[kendoDialList]',
        template: `
        <ng-container *ngFor='let item of dialItems; let idx = index'>
            <li
                kendoButtonFocusable
                kendoDialItem
                [item]="dialItems[idx]"
                [index]="idx"
                [dialItemTemplate]="dialItemTemplate"
                [isFocused]="isFocused(idx)"
                [ngClass]='item.cssClass'
                [ngStyle]='item.cssStyle'
                [align]="align"
            >
            </li>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [FocusService, ChangeDetectorRef])
], DialListComponent);

/**
 * @hidden
 */
let DialItemComponent = class DialItemComponent {
    constructor(element, renderer, localisationService) {
        this.element = element;
        this.renderer = renderer;
        this.localisationService = localisationService;
        this.hostClass = true;
        this.role = 'menuitem';
    }
    get disabledClass() {
        return this.item.disabled;
    }
    get title() {
        const label = this.item.label;
        return label ? label : this.itemTitle;
    }
    get indexAttr() {
        return this.index;
    }
    get iconClass() {
        if (this.item.iconClass) {
            return `${this.item.iconClass}`;
        }
        return `k-fab-item-icon k-icon k-i-${this.item.icon}`;
    }
    get itemTitle() {
        const icon = this.item.icon;
        const itemTitle = this.item.itemTitle;
        return (icon && itemTitle) ? itemTitle : icon;
    }
    ngAfterViewInit() {
        const element = this.element.nativeElement;
        const rtl = this.localisationService.rtl;
        const hAlign = this.align.horizontal;
        this.renderer.addClass(element, this.getTextDirectionClass(rtl, hAlign));
    }
    getTextDirectionClass(rtl, hAlign) {
        const dir = rtl ? 'rtl' : 'ltr';
        const align = hAlign === 'end' ? 'end' : 'start';
        const directions = {
            rtl: { end: 'k-text-left', start: 'k-text-right' },
            ltr: { start: 'k-text-left', end: 'k-text-right' }
        };
        return directions[dir][align];
    }
};
__decorate([
    HostBinding('class.k-fab-item'),
    __metadata("design:type", Boolean)
], DialItemComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], DialItemComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.aria-disabled'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DialItemComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('attr.title'),
    HostBinding('attr.aria-label'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DialItemComponent.prototype, "title", null);
__decorate([
    HostBinding('attr.data-fab-item-index'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DialItemComponent.prototype, "indexAttr", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialItemComponent.prototype, "cssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialItemComponent.prototype, "cssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DialItemComponent.prototype, "isFocused", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialItemComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialItemComponent.prototype, "item", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], DialItemComponent.prototype, "dialItemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DialItemComponent.prototype, "align", void 0);
DialItemComponent = __decorate([
    Component({
        selector: '[kendoDialItem]',
        template: `
        <ng-template *ngIf="dialItemTemplate"
            [ngTemplateOutlet]="dialItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: item, index: index, isFocused: isFocused }"
        >
        </ng-template>

        <ng-container *ngIf="!dialItemTemplate">
            <span *ngIf="item.label" class="k-fab-item-text">{{ item.label }}</span>
            <span *ngIf="item.icon" [ngClass]="iconClass"></span>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        LocalizationService])
], DialItemComponent);

const exportedModules$1 = [
    FloatingActionButtonComponent,
    DialItemTemplateDirective,
    FloatingActionButtonTemplateDirective
];
const declarations$1 = [
    ...exportedModules$1,
    DialListComponent,
    DialItemComponent
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the FloatingActionButton component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the FloatingActionButton module
 * import { FloatingActionButtonModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FloatingActionButtonModule], // import FloatingActionButton module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
let FloatingActionButtonModule = class FloatingActionButtonModule {
};
FloatingActionButtonModule = __decorate([
    NgModule({
        declarations: [declarations$1],
        exports: [exportedModules$1],
        imports: [CommonModule, ListModule, EventsModule]
    })
], FloatingActionButtonModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmodules'] }})
 * definition for the Buttons components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Buttons module
 * import { ButtonsModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ButtonsModule], // import Buttons module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let ButtonsModule = class ButtonsModule {
};
ButtonsModule = __decorate([
    NgModule({
        exports: [ButtonGroupModule, ButtonModule, SplitButtonModule, DropDownButtonModule, ChipModule, FloatingActionButtonModule]
    })
], ButtonsModule);

/**
 * Generated bundle index. Do not edit.
 */

export { KendoButtonService, DialItemComponent, DialListComponent, FocusService, FocusableDirective, ButtonItemTemplateDirective, ListButton, ListComponent, ListModule, TemplateContextDirective, NAVIGATION_CONFIG, NavigationService, ButtonDirective, ButtonDirective as Button, ButtonGroupComponent, ButtonGroupComponent as ButtonGroup, ButtonGroupModule, ButtonModule, ButtonsModule, SplitButtonComponent, SplitButtonComponent as SplitButton, SplitButtonModule, DropDownButtonComponent, DropDownButtonComponent as DropDownButton, DropDownButtonModule, ChipComponent, ChipListComponent, ChipModule, FloatingActionButtonModule, FloatingActionButtonComponent, FloatingActionButtonTemplateDirective, DialItemTemplateDirective };
